import './cart-dropdown.style.css';

import { Button } from 'react-bootstrap';

import { useSelector,useDispatch } from 'react-redux';

import { selectCartItems,selectIsCartOpen } from '../../store/cart/cart.selector';

import { setIsCartOpen } from '../../store/cart/cart.action';

import CartItem from '../cart-item/cart-item.component';

import { useNavigate } from 'react-router-dom';

const CartDropdown = ()=>{

  const cartItems = useSelector(selectCartItems);

  const dispatch = useDispatch();

  const isCartOpen = useSelector(selectIsCartOpen);

  const navigate = useNavigate();

  const redirectToCheckout = ()=>{
    navigate('/checkout');
    dispatch(setIsCartOpen(!isCartOpen));
  }

    return (
        <div className='cart-dropdown-container'>
        <div className='cart-items'>
          { cartItems.length < 1 ? <p>Your cart is empty</p>
            : cartItems.map((item)=>{
              return <CartItem key={item.id} product={item} />
            })
          }
          
        </div>
        <Button onClick={redirectToCheckout} variant='warning'>GO TO CART</Button>
      </div>
    
  );
  

    
}

export default CartDropdown;