import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store,persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { UserProvider } from './contexts/user.contexts';
import { HelmetProvider } from 'react-helmet-async';


// const root = ReactDOM.createRoot(document.getElementById('root'));
ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider> 
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <UserProvider>
          <BrowserRouter>
                 <App /> 
        </BrowserRouter>
         </UserProvider>
         </PersistGate>
        
       </Provider>
     </HelmetProvider>  
     </React.StrictMode>   
,
  document.getElementById('root')
     
  
  
    
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
