import { Fragment, useState } from "react";
import { Button,Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Footer/footer.component";
import './signup.style.css'
import { useNavigate } from "react-router-dom";
import { signUpStart } from "../../store/user/user.action";
import { useDispatch,useSelector} from 'react-redux';
import {selectCurrentUser,selectError} from '../../store/user/user.selector';
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import {Spinner} from 'react-bootstrap';


const Signup = ()=>{

    const [showSpinner,setShowSpinner] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const currentUser = useSelector(selectCurrentUser);

    const errorCode = useSelector(selectError);

    useEffect(()=>{
        setShowSpinner(false);
        if(currentUser){
            navigate('/');
        }

        if(errorCode){
            handleError(errorCode);
        }
        

    },[currentUser,errorCode])



    const handleError = (errorCode)=>{
        if(errorCode==='auth/weak-password'){
            alert('Password length must be minimum 6 character!')
        }

        else if (errorCode==='auth/email-already-in-use'){
            alert('User already exist with email id! Please sign in')
        }


    }

  
    
    const defaultFormFields = {
        firstname : '',
        lastname: '',
        email : '',
        password: '',
        confirmpassword:'',
        displayName : ''

    }

    const [formFields,setFormFields] = useState(defaultFormFields);
    
    const {firstname,lastname,email,password,confirmpassword,displayName} = formFields;
    
    const handleFormField=(event)=>{
        const {name,value} = event.target;
        setFormFields({...formFields,[name]:value});
    }

    const handleFormSubmit = (event)=> {
        event.preventDefault();
        setShowSpinner(true);
        if(password===confirmpassword){
                dispatch(signUpStart(email,password,displayName,firstname,lastname));
                setFormFields(defaultFormFields);            
         }
         else {
             alert('Confirm Password not same!');
         }
        }
    return (
        <Fragment>
            <Helmet>
                <title>Create an Account | Sign up to Ahguide</title>
                <meta name="description" content="You can create an account or signup using your email and password or signup using google account" />        
                <link rel="canonical"  href="https://www.ahguide.in/signup" />
            </Helmet>
        <div className="login-container">
            <Form onSubmit={handleFormSubmit} className="form-container">
                <p>Sign up with</p>
                <Button variant="light"><i className="fa-brands fa-google"></i> Google</Button>
                
                <hr />
                <p>Or sign up with below details</p>
        <Form.Group className="mb-3" controlId="formGroupFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control onChange={handleFormField} type="text" placeholder="First Name" name='firstname' value={firstname} />
        </Form.Group> 
        <Form.Group className="mb-3" controlId="formGroupLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control onChange={handleFormField} type="text" placeholder="Last Name" name='lastname' value={lastname} />
        </Form.Group>   

        <Form.Group className="mb-3" controlId="formGroupDisplayName">
            <Form.Label>Display Name</Form.Label>
            <Form.Control onChange={handleFormField} type="text" placeholder="Display Name" name='displayName' value={displayName} />
        </Form.Group>  


            
        <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control onChange={handleFormField} type="email" placeholder="Enter email" name='email' value={email} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control onChange={handleFormField} type="password" placeholder="Password" name='password' value={password} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control onChange={handleFormField} type="password" placeholder="Confirm Password" name='confirmpassword' value={confirmpassword} />
        </Form.Group>
        {
            showSpinner ?  <Spinner animation="border" variant="danger"/>
                        :  <Button variant="danger" type="submit">
                            Sign Up
                           </Button>
        }
       
        <div>Already have an account with us? <Link to='/login'>Sign in</Link></div>
        </Form>  
        
</div>
<Footer />
</Fragment>


        

    )
}

export default Signup;