import "animate.css";
import "./animated-notification.style.css";
import { Link } from "react-router-dom";
import { getNotificationData } from "../../utils/firebase/firebase.utils";
import { useState, useEffect } from "react";

const defaultValue = {
  text: "Pay Tution Fees",
};

const AnimatedNotification = () => {
  const [data, setData] = useState(defaultValue);
  const { text } = data;

  useEffect(() => {
    const getData = async () => {
      const animatedData = await getNotificationData();
      setData(animatedData);
    };
    getData();
  }, []);

  return (
    <div className="animation-container">
      <Link to="/latest-notification" className="link">
        <p className="animate__animated animate__shakeX animate__repeat-2">
          {text}
        </p>
      </Link>
    </div>
  );
};

export default AnimatedNotification;
