import { initializeApp } from "firebase/app";

import {
  query,
  writeBatch,
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  FacebookAuthProvider,
  getRedirectResult,
} from "firebase/auth";

import { getStorage, ref, getDownloadURL, uploadBytes,listAll } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "aainagirl.firebaseapp.com",
  projectId: "aainagirl",
  storageBucket: "aainagirl.appspot.com",
  messagingSenderId: "453121687141",
  appId: process.env.REACT_APP_FIREBASE_API_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export const storage = getStorage();

// reference to image in storage location

export const gsReference = ref(
  storage,
  "gs://aainagirl.appspot.com/slider/ahguide-wbcs.jpg"
);

const storageRef = ref(storage, "ahguide.jpg");

// getting download link for image

export const downloadImage = async () => {
  const response = await getDownloadURL(storageRef);

  return response;
};

// uploading image to firebase storage

export const uploadImage = async (image) => {
  await uploadBytes(storageRef, image).then((snapsort) => {
    alert("Uploading Done");
  });
};

// Send data to Database from Signin/SignUp

export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  if (!userAuth) return;

  const userDocRef = doc(db, "users", userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    const cartItems = [];
    const mock = {};
    const score = 0;

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        cartItems,
        mock,
        score,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  }

  return userSnapshot;
};

const auth = getAuth();

const GoogleProvider = new GoogleAuthProvider();

export const GoogleLoginWithPopup = () => signInWithPopup(auth, GoogleProvider);

export const GoogleLoginWithRedirect = () =>
  signInWithRedirect(auth, GoogleProvider);

export const getRedirectResultFromAuth = () => getRedirectResult(auth);

export const signInwithEmail = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const createUserwithEmail = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const onAuthStateChangedListener = (callback) => {
  onAuthStateChanged(auth, callback);
};

export const signOutUser = async () => await signOut(auth);

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};

const FacebookProvider = new FacebookAuthProvider();

export const FacebookLoginWithPopup = () =>
  signInWithPopup(auth, FacebookProvider);

// Adding ias/wbcs/courses data to firestore database

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const batch = writeBatch(db);
  const collectionRef = collection(db, collectionKey);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.name.toLowerCase());
    batch.set(docRef, object.item);
  });

  await batch.commit();
  console.log("done");
};

// Adding Home Intro Cards data to Database

export const addHomeIntroCardsData = async (collectionKey, objectsToAdd) => {
  const batch = writeBatch(db);
  const collectionRef = collection(db, collectionKey);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.name.toLowerCase());
    batch.set(docRef, object.item);
  });

  await batch.commit();
  console.log("done");
};

// Get Home Intro Title From Database

export const getHomeIntroTitleFromDatabase = async () => {
  const introTitle = await getDoc(doc(db, "introcards", "introtitle"));
  return introTitle.data().title;
};

// Get Home Intro Sub Header From DataBase

export const getHomeIntroSubHeaderFromDatabase = async () => {
  const introSubHeader = await getDoc(doc(db, "introcards", "introsubheader"));
  return introSubHeader.data().subheader;
};

// Get Home Intro Description From Database

export const getHomeIntroDescriptionFromDatabase = async () => {
  const introDescription = await getDoc(
    doc(db, "introcards", "introdescription")
  );
  return introDescription.data().description;
};

// Get Home Intro Card Data from Database

export const getHomeIntroData = async (collectionName) => {
  const itemRef1 = doc(db, collectionName, "card1");
  const docSnap1 = await getDoc(itemRef1);
  const itemRef2 = doc(db, collectionName, "card2");
  const docSnap2 = await getDoc(itemRef2);
  const itemRef3 = doc(db, collectionName, "card3");
  const docSnap3 = await getDoc(itemRef3);
  const itemRef4 = doc(db, collectionName, "card4");
  const docSnap4 = await getDoc(itemRef4);
  return [docSnap1.data(), docSnap2.data(), docSnap3.data(), docSnap4.data()];
};

// Get Home Courses Data from Database

export const getHomeCoursesData = async (collectionName) => {
  const itemRef1 = doc(db, collectionName, "course1");
  const docSnap1 = await getDoc(itemRef1);
  const itemRef2 = doc(db, collectionName, "course2");
  const docSnap2 = await getDoc(itemRef2);
  const itemRef3 = doc(db, collectionName, "course3");
  const docSnap3 = await getDoc(itemRef3);
  const itemRef4 = doc(db, collectionName, "course4");
  const docSnap4 = await getDoc(itemRef4);
  const itemRef5 = doc(db, collectionName, "course5");
  const docSnap5 = await getDoc(itemRef5);
  const itemRef6 = doc(db, collectionName, "course6");
  const docSnap6 = await getDoc(itemRef6);
  return [
    docSnap1.data(),
    docSnap2.data(),
    docSnap3.data(),
    docSnap4.data(),
    docSnap5.data(),
    docSnap6.data(),
  ];
};

// Get Home Courses Title

export const getHomeCourseTitle = async () => {
  const courseTitle = await getDoc(doc(db, "courses", "courseTitle"));
  return courseTitle.data().title;
};

// Get Home Courses Description

export const getHomeCoursesDescription = async () => {
  const courseDescription = await getDoc(
    doc(db, "courses", "courseDescription")
  );
  return courseDescription.data().description;
};

// Get Contact Form Address From Database

export const getContactFormAddress = async () => {
  const courseDescription = await getDoc(
    doc(db, "contactformdetails", "address")
  );
  return courseDescription.data().address;
};

// Get Contact Form Email1 From Database

export const getContactFormEmail1 = async () => {
  const courseDescription = await getDoc(
    doc(db, "contactformdetails", "email1")
  );
  return courseDescription.data().email;
};

// Get Contact Form Email1 From Database

export const getContactFormEmail2 = async () => {
  const courseDescription = await getDoc(
    doc(db, "contactformdetails", "email2")
  );
  return courseDescription.data().email;
};

// Get Contact Form Mobile From Database

export const getContactFormMobile = async () => {
  const courseDescription = await getDoc(
    doc(db, "contactformdetails", "mobile")
  );
  return courseDescription.data().mobile;
};

// Get Footer Title and Description from Database

export const getFooterTitleDescription = async () => {
  const footerItems = await getDoc(doc(db, "footer", "item"));
  return footerItems.data();
};

// Get Footer Contact Details from Database

export const getFooterContactDetails = async () => {
  const footerContact = await getDoc(doc(db, "footer", "contact"));
  return footerContact.data();
};

// Get Wbcs/IAS Component Title from Firebase

export const getCourseTitle = async (collectionName) => {
  const docRef = doc(db, collectionName, "header");
  const cardHeaderSnap = await getDoc(docRef);
  return cardHeaderSnap.data();
};

// Get Wbcs/Ias Component Cards Data

export const getCourseData = async (data) => {
  const itemRef1 = doc(db, data, "item1");
  const docSnap1 = await getDoc(itemRef1);
  const itemRef2 = doc(db, data, "item2");
  const docSnap2 = await getDoc(itemRef2);
  const itemRef3 = doc(db, data, "item3");
  const docSnap3 = await getDoc(itemRef3);
  const itemRef4 = doc(db, data, "item4");
  const docSnap4 = await getDoc(itemRef4);
  return [docSnap1.data(), docSnap2.data(), docSnap3.data(), docSnap4.data()];
};

// Set CartItems to User Document
export const setCartItemsToDatabase = async (uid, cartItems) => {
  const userDocRef = doc(db, "users", uid);

  try {
    await updateDoc(userDocRef, {
      cartItems: cartItems,
    });
  } catch (error) {
    console.log(error.message);
  }
};

// Get cartItems from User Document

export const getCartItemsFromUserDatabase = async (uid) => {
  const userDocRef = doc(db, "users", uid);

  try {
    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) return;

    return userSnapshot.data().cartItems.map((item) => item);
  } catch (error) {
    console.log(error.message);
  }
};

// Get user details from database

export const getUserDetailsFromUserDatabase = async (uid) => {
  const userDocRef = doc(db, "users", uid);

  try {
    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) return;

    return userSnapshot.data();
  } catch (error) {
    console.log(error.message);
  }
};

// Add Contact Details to Database

export const addContactFormDetails = async (formFieldData) => {
  const { firstname, lastname, email, message, mobile } = formFieldData;

  await setDoc(doc(db, "contactform", email), {
    firstname: firstname,
    lastname: lastname,
    email: email,
    message: message,
    mobile: mobile,
  });
};

// Post order details to Database

export const postOrderDetails = async (orderDetails, orderid) => {
  const { fullname, email, address, pincode, mobile } = orderDetails;

  await setDoc(doc(db, "orderdetails", email), {
    name: fullname,
    email: email,
    address: address,
    mobile: mobile,
    pincode: pincode,
    orderid: orderid,
  });
};

// Get order details from Database

export const getOrderDetails = async (uid) => {
  const userDocRef = doc(db, "orderdetails", uid);

  try {
    const userSnapshot = await getDoc(userDocRef);

    if (!userSnapshot.exists()) return;

    return userSnapshot.data();
  } catch (error) {
    console.log(error.message);
  }
};

// Post Tution Fee Payment to Database

export const postTutionPaymentDetails = async (orderDetails, orderid) => {
  const lastOrderdRef = doc(db, "tutionfees", "ordernumber");
  const lastOrderDocSnap = await getDoc(lastOrderdRef);
  let lastOrderId = lastOrderDocSnap.data().lastorder;
  lastOrderId++;
  const { name, email, mobile, amount, address } = orderDetails;
  const number = lastOrderId.toString();

  await setDoc(doc(db, "tutionfees", number), {
    name: name,
    email: email,
    address: address,
    mobile: mobile,
    fees: amount,
    orderid: orderid,
    ordernumber: lastOrderId,
    orderplaced: new Date(),
  });

  await setDoc(doc(db, "tutionfees", "ordernumber"), {
    lastorder: lastOrderId,
  });
};

// Get Tution all payment details
export const getallfeesdata = async () => {
  const q = query(
    collection(db, "tutionfees"),
    orderBy("ordernumber", "desc"),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot;
};

//Post Mock Test Result to User Account

export const postMockResultsToDatabase = async (uid, mock, score) => {
  const userDocRef = doc(db, "users", uid);

  try {
    await updateDoc(userDocRef, {
      mock: mock,
      score: score,
    });
  } catch (error) {
    console.log(error.message);
  }
};

// get Wbcs Math Data
export const getWbcsMath = async () => {
  const docRef = doc(db, "wbcssubject", "math");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs Geography Data
export const getWbcsGeography = async () => {
  const docRef = doc(db, "wbcssubject", "geography");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs History Data
export const getWbcsHistory = async () => {
  const docRef = doc(db, "wbcssubject", "history");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get General Science Data
export const getWbcsGeneralScience = async () => {
  const docRef = doc(db, "wbcssubject", "generalscience");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs Polity Data
export const getWbcsPolity = async () => {
  const docRef = doc(db, "wbcssubject", "polity");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs Economy Data
export const getWbcsEconomy = async () => {
  const docRef = doc(db, "wbcssubject", "economy");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs GI Data
export const getWbcsGI = async () => {
  const docRef = doc(db, "wbcssubject", "gi");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs Static GK Data
export const getWbcsStaticGk = async () => {
  const docRef = doc(db, "wbcssubject", "staticgk");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs Static CI Data
export const getWbcsCa = async () => {
  const docRef = doc(db, "wbcssubject", "ca");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs English Grammer Data
export const getWbcsEnglishGrammer = async () => {
  const docRef = doc(db, "wbcssubject", "enggrammer");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Wbcs English Vocabulary Data
export const getWbcsEnglishVocabulary = async () => {
  const docRef = doc(db, "wbcssubject", "engvocabulary");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get IAS Data
export const getIasDataMenu = async () => {
  const docRef = doc(db, "wbcssubject", "iasdata");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Current Afairs Data
export const getCurrentAffairs = async () => {
  const docRef = doc(db, "wbcssubject", "currentaffairs");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get First Courses Card Data
export const getCoursesFirstCard = async () => {
  const docRef = doc(db, "courses", "firstcoursescard");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Second Courses Card Data
export const getCoursesSecondCard = async () => {
  const docRef = doc(db, "courses", "secondcoursescard");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Third Courses Card Data
export const getCoursesThirdCard = async () => {
  const docRef = doc(db, "courses", "thirdcoursescard");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Forth Courses Card Data
export const getCoursesForthCard = async () => {
  const docRef = doc(db, "courses", "forthcoursescard");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Fifth Courses Card Data
export const getCoursesFifthCard = async () => {
  const docRef = doc(db, "courses", "fifthcoursescard");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Sixth Courses Card Data
export const getCoursesSixthCard = async () => {
  const docRef = doc(db, "courses", "sixthcoursescard");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// get Running Notification Data
export const getNotificationData = async () => {
  const docRef = doc(db, "notification", "animated");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};

// Get Notification data to update

export const getNotificationDataUpdate = async () => {
  const docRef = doc(db, "notification", "update");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};


// Get Testimonials

export const getTestimonial = async () => {
  const docRef = doc(db, "testimonial", "alumni");

  const querySnapshot = await getDoc(docRef);
  return querySnapshot.data();
};