import { Typography,Box } from "@mui/material";
import { useState,useEffect, Fragment } from "react";
import CoursesBlog from "../../../../Blog/courses-blog.component";
import {getWbcsGeneralScience} from "../../../../../utils/firebase/firebase.utils";
import { Helmet } from "react-helmet-async";

const WbcsGeneralScience = ()=>{

    const [courseData,setCourseData] = useState([]);

    useEffect(()=>{
        const getData = async()=>{
            const arrayData = await getWbcsGeneralScience();
            const{data} = arrayData;
            setCourseData(data.reverse());
        
        }

        getData();
        
        
    },[])




    return(
        <Fragment>
            <Helmet>
                <title>WBCS Study Material General Science : Prelims and Mains Study Plan</title>
                <meta name="description" content="Get daily study material and mock test on General Science to crack WBCS Prelims and Mains exam . " />        
                <link rel="canonical" href="https://www.ahguide.in/wbcs/study-material-general-science" />
            </Helmet>
        <Box>
            
            <Typography sx={{textAlign:"center",marginTop:'20px'}} variant="h3" component="div">
                General Science
            </Typography>
            {
                courseData.map((data)=>{
                    return  <CoursesBlog key={data.title} courseData= {data}  />
                })
            }

        </Box>
        </Fragment>
    )

}

export default WbcsGeneralScience;