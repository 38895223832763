import './quiz.style.css';
import {Fragment, useState} from 'react';
import { Button,Card } from 'react-bootstrap';
import Footer from '../Footer/footer.component';

let click = 0;
const quizData =[
    {
    question : 'What is the capital of India ?',
    option1: 'New Delhi',
    option2: 'Mumbai',
    correctAnswer : 'New Delhi'
    },
    {
    question: "Which is open source OS ?",
    option1: 'Linux',
    option2:'Windows',
    correctAnswer : 'Linux'
    }
]

const Quiz = ()=>{
    const [index,setIndex] = useState(0);
    const [score,setScore] = useState(0);
    const nextIndex=()=>{
        index===quizData.length-1 ? setIndex(index) 
                                  : setIndex(index+1)
        
        
    }

    const previousIndex=()=>{

        setIndex(index-1);
    }

    const handleAnswer = (e)=>{
       click++;
       if(click===1) {
        const dataType = isNaN(quizData[index].option1);
        if(dataType){
            const userAnswer = e.target.innerText;
            if(userAnswer===quizData[index].option1){
                setScore(score+20);
                click--;
            }
            
        }
        else {
            const userAnswer = Number(e.target.innerText);
            if(userAnswer===quizData[index].option1){
                setScore(score+20);
                click--;
            }
            
        }

       }
        
        

    }

    
    return (
        <Fragment>
        <div className='quiz-container'>
<Card className='quiz-card'>
  <Card.Header as="h5">WBCS Mock Test Quiz</Card.Header>
  <Card.Body>
    <Card.Title>Question:{quizData[index].question}</Card.Title>
    <Card className='quiz-option' body onClick={handleAnswer} >{quizData[index].option1}</Card>
    <Card className='quiz-option' body onClick={handleAnswer}>{quizData[index].option2}</Card>
    {
                index===0 ? <Button variant='primary' onClick={previousIndex} hidden>Previous</Button>
                           : <Button variant='primary' onClick={previousIndex}>Previous</Button>
            }
    <Button style={{margin:'10px'}} variant='primary' onClick={nextIndex}>Next</Button> 
            {/* <div>Score: {score}</div>          */}
    
  </Card.Body>
</Card>

        </div>
        <Footer />
        </Fragment>
    )
        
}            
export default Quiz;