import { useEffect, useState } from "react";
import { getallfeesdata } from "../../../utils/firebase/firebase.utils";
import Footer from "../../Footer/footer.component";
import { Helmet } from "react-helmet-async";

const defaultOrderData = {
  name: "",
  email: "",
  mobile: "",
  address: "",
  fees: "",
  ordernumber: "",
};

const TutionPaymentSuccess = () => {
  const [date, setDate] = useState(new Date().getTime());

  const [userdata, setUserData] = useState(defaultOrderData);
  const { name, email, mobile, address, fees, ordernumber } = userdata;

  useEffect(() => {
    const paymentDetails = async () => {
      const getalldata = await getallfeesdata();
      getalldata.forEach((doc) => {
        setUserData(doc.data());
      });
    };

    paymentDetails();
    const currentDate = new Date(date);
    const time = currentDate.toString();
    setDate(time);
  }, []);

  return (
    <>
      <Helmet>
        <title>Order Placed | Payment Confirmation Page </title>
        <meta
          name="description"
          content="We have received your payment. Below is your payment details and you will also receive payment confirmation email shortly."
        />
        <link
          rel="canonical"
          href="https://www.ahguide.in/tution-fees-payment-confirmation"
        />
      </Helmet>

      <div
        style={{
          margin: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <h1>Payment Received</h1>
        <p>
          We have received your payment. Below is your payment details and you
          will also receive payment confirmation email shortly.
        </p>
        <p>Thank You! </p>
        <div
          style={{
            fontSize: 20,
            border: "2px solid #555",
            padding: "10px",
            margin: "0 auto",
            boxSizing: "border-box",
            marginBottom: "20px",
          }}
        >
          <p>Order Number: {ordernumber}</p>
          <p>Order Placed : {date}</p>
          <p>Name : {name}</p>
          <p>Email: {email}</p>
          <p>Mobile: {mobile}</p>
          <p>Address: {address}</p>
          <p>
            <strong>Amount Paid : Rs. {fees}</strong>
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TutionPaymentSuccess;
