import { Fragment } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Container, Paper, Typography } from "@mui/material";
import "./contact.style.css";
import { Helmet } from "react-helmet-async";

import {
  addContactFormDetails,
  getContactFormAddress,
  getContactFormEmail1,
  getContactFormEmail2,
  getContactFormMobile,
} from "../../utils/firebase/firebase.utils";

import { useState, useEffect } from "react";

import { Spinner } from "react-bootstrap";

import DescriptionAlerts from "../Feedbacks/Alert/alert-success.component";
import SendButton from "../Buttons/Send/send-button.component";
import Footer from "../Footer/footer.component";

const Contact = () => {
  const [showProgress, setShowProgress] = useState(true);

  const [showAlert, setShowAlert] = useState(false);

  const [address, SetAddress] = useState(
    "New Bus Stand, Rampuhat,West Bengal 731224"
  );

  const [email1, SetEmail1] = useState("support@ahguide.in");

  const [email2, SetEmail2] = useState("ahguide2019@gmail.com");

  const [contactMobile, SetContactMobile] = useState("7098230513");

  useEffect(() => {
    const getContactFormData = async () => {
      const getAddress = await getContactFormAddress();
      const getEmail1 = await getContactFormEmail1();
      const getEmail2 = await getContactFormEmail2();
      const getMobile = await getContactFormMobile();
      SetAddress(getAddress);
      SetEmail1(getEmail1);
      SetEmail2(getEmail2);
      SetContactMobile(getMobile);
    };

    getContactFormData();
  }, []);

  const defaultFields = {
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    message: "",
  };

  const [formFields, setFormFields] = useState(defaultFields);

  const { firstname, lastname, email, mobile, message } = formFields;

  const formFieldsHandler = (e) => {
    const { name, value } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const contactFormHandler = async (e) => {
    e.preventDefault();
    setShowProgress(false);

    const response = await fetch("/.netlify/functions/nodemailer-send-msg", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email, name: firstname }),
    });

    if (response) {
      setShowProgress(true);
      setShowAlert(true);
      addContactFormDetails(formFields);
      setFormFields(defaultFields);
    } else {
      alert("error");
    }
  };

  return (
    <Fragment>
      {window.location.href === "https://www.ahguide.in/" ? (
        <Helmet>
          <title>
            Best WBCS Coaching Online | WBCS Exam Preparation Institute |
            Ahguide
          </title>
          <meta
            name="description"
            content="Ahguide provides the best coaching in kolkata for WBCS and IAS. Offering advanced education, study materials, practice and doubt solving sessions and mock tests."
          />
          <link rel="canonical" href="https://www.ahguide.in" />
        </Helmet>
      ) : (
        <Helmet>
          <title> Contact Us | Ahguide </title>
          <meta
            name="description"
            content="You can contact us by filling below contact us form or you can call us directly or visit our institute."
          />
          <link rel="canonical" href="https://www.ahguide.in/contact-us" />
        </Helmet>
      )}

      <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
        <Paper elevation={2}>
          <div className="contact-container">
            <Form
              onSubmit={contactFormHandler}
              className="contact-form-container"
            >
              <Typography
                variant="h3"
                component="div"
                sx={{ textAlign: "center", marginBottom: "10px" }}
              >
                Contact Us
              </Typography>

              {showAlert && <DescriptionAlerts />}
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    onChange={formFieldsHandler}
                    type="text"
                    placeholder="First Name"
                    name="firstname"
                    value={firstname}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    onChange={formFieldsHandler}
                    type="text"
                    placeholder="Last Name"
                    name="lastname"
                    value={lastname}
                    required
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={formFieldsHandler}
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={email}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    onChange={formFieldsHandler}
                    type="phone"
                    placeholder="Mobile"
                    name="mobile"
                    value={mobile}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Label>Message</Form.Label>

                <Form.Control
                  onChange={formFieldsHandler}
                  as="textarea"
                  placeholder="Message"
                  rows={6}
                  name="message"
                  value={message}
                  required
                />
              </Form.Group>
              {!showProgress ? (
                <Spinner animation="border" variant="warning" />
              ) : (
                <SendButton />
              )}
            </Form>
            <div className="address-info">
              <Typography
                variant="h3"
                component="div"
                sx={{ textAlign: "center", marginBottom: "10px" }}
              >
                Address
              </Typography>
              <div className="address-container">
                <p>
                  <i className="fa-solid fa-location-dot fa-xl"></i>
                  &nbsp;&nbsp;&nbsp; {address}
                </p>
                <p>
                  <i className="fa-solid fa-envelope fa-xl"></i>
                  &nbsp;&nbsp;&nbsp; {email1}
                </p>
                <p>
                  <i className="fa-solid fa-envelope fa-xl"></i>
                  &nbsp;&nbsp;&nbsp; {email2}
                </p>
                <p>
                  <i className="fa-solid fa-mobile fa-xl"></i>&nbsp;&nbsp;&nbsp;{" "}
                  {contactMobile}
                </p>
              </div>
            </div>
          </div>
        </Paper>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default Contact;
