import Carousel from 'react-material-ui-carousel';
import { Helmet } from "react-helmet-async";
import TestimonialCard from "./testimonial-card.component";
import { Container,Box,Card,CardContent,Avatar,Typography,Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getTestimonial } from "../../utils/firebase/firebase.utils";




const cardData1 = [
    {
        url: "https://firebasestorage.googleapis.com/v0/b/aainagirl.appspot.com/o/testimonials%2Fpratul.jpg?alt=media&token=484ea65f-fd0a-40a8-9b82-93b4aefacd26",
        title: 'Pratul Mondal',
        description : "WBCS, Group A (Revenue Service) 2019 Batch"
    },
    {
        url: "https://firebasestorage.googleapis.com/v0/b/aainagirl.appspot.com/o/testimonials%2Fsarifa.jpg?alt=media&token=7ea09e62-f11b-4386-a7e0-f2a1145df966",
        title: 'Sarifa Banu',
        description : "ICDS Supervisor"
    },
    {
        url: "https://firebasestorage.googleapis.com/v0/b/aainagirl.appspot.com/o/testimonials%2Fenjamamul.jpg?alt=media&token=ec66eab1-2dc6-4d7a-8e4d-66f3c6e18404",
        title: 'Enjamamul Hassan',
        description : "SI, WB Police"
    },
    {
        url: "https://firebasestorage.googleapis.com/v0/b/aainagirl.appspot.com/o/testimonials%2Fpujita.jpg?alt=media&token=6b516ceb-898f-4ef2-8df5-582b3a1d9df6",
        title: 'Pujita Halder',
        description : "ICDS Supervisor"
    },

    {
        url: "https://firebasestorage.googleapis.com/v0/b/aainagirl.appspot.com/o/testimonials%2Fsaraban.jpg?alt=media&token=6231f83d-f4fc-43ca-a9cf-967248e34236",
        title: 'Saraban Johura',
        description : "ICDS Supervisor"
    },
    {
        url: "https://firebasestorage.googleapis.com/v0/b/aainagirl.appspot.com/o/testimonials%2Farif.jpg?alt=media&token=0d1779e1-4ef0-4882-a3ab-5e242cd250fa",
        title: 'Arif Yea Mondal',
        description : "LDC, Fishery Dept. & WBCS 'Group A' 2021 Mains Qfd"
    },
  
    
    
]

const Testimonial = ()=>{

    const [review,setReview] = useState(cardData1);
    
    

    useEffect(()=>{
        const getData = async()=>{
           
            const testimonial = await getTestimonial();
            const {data} = testimonial;
        
            setReview(data);

            

        }

        getData();
    },[])
    
    return (
        <>
        {
        (window.location.href === 'https://www.ahguide.in/') ? <Helmet>
            <title>Best WBCS Coaching Online | WBCS Exam Preparation Institute | Ahguide</title>
          <meta name="description" content="Ahguide provides the best coaching in kolkata for WBCS and IAS. Offering advanced education, study materials, practice and doubt solving sessions and mock tests." />
          <link rel="canonical" href="https://www.ahguide.in" />
        </Helmet>
        : 
        <Helmet>
                <title> Testimonials | Our Alumni | Our Results  </title>
                <meta name="description" content="Ahguide testimonials by our students and alumni, our success results." />        
                <link rel="canonical"  href="https://www.ahguide.in/testimonial" />
            </Helmet>
          }
        <Container maxWidth="xl" sx={{marginTop:"10px"}}>
         <Typography variant="h3" component="div" sx={{textAlign:'center',marginBottom:'10px'}}>

             Our Alumni
            
        </Typography>   
        <Carousel>
            {
                review.map((element,i)=>{
                    let length = review.length;
                    var j = i;
                    if(i===length-1){

                        j = -1;

                    }
      
                    return <TestimonialCard data1={review[i]} data2={review[j+1]} key={i} />

                    
                    
                })
            }

            
            
            
        </Carousel>
        </Container>
        </>
        
    )

}

export default Testimonial;