import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation/navigation.components";
import Login from "./components/Login/login.components";
import Home from "./routes/Home";
import Signup from "./components/Signup/signup.components";
import Contact from "./components/Contact/contact.component";
import Mock from "./routes/Mock/mock.component";
import Quiz from "./components/Quiz/quiz.component";
import Wbcs from "./components/Mock/wbcs/wbcs.component";
import { Fragment, useEffect } from "react";
import {
  addCollectionAndDocuments,
  onAuthStateChangedListener,
  addHomeIntroCardsData,
} from "./utils/firebase/firebase.utils";
import { WbcsData } from "./data/wbcs/wbcs.data";
import { IasData } from "./data/ias/ias.data";
import { introCardData } from "./data/intro/cards.data";
import { courseData } from "./data/courses/courses.data";
import Ias from "./components/Mock/ias/ias.component";
import { checkUserSession } from "./store/user/user.action";
import { useDispatch } from "react-redux";
import Checkout from "./routes/Checkout/checkout.route";
import CheckoutBillingAddress from "./routes/Checkout/checkout-billing-address.routes";
import PaymentSuccess from "./components/payment-success/payment-success.component";
import WbcsStudyMaterial from "./components/StudyMaterial/wbcs-study-material.component";
import WbcsQuestion from "./components/StudyMaterial/Wbcs/Question/wbcs-question.component";
import WbcsAnswerKey from "./components/StudyMaterial/Wbcs/Answer/wbcs-answer.component";
import WbcsMockQuiz1 from "./components/Mock/wbcs/quiz/module1/wbcs.quiz1";
import WbcsMockQuiz2 from "./components/Mock/wbcs/quiz/module2/wbcs.quiz2";
import WbcsMockQuiz3 from "./components/Mock/wbcs/quiz/module3/wbcs.quiz3";
import WbcsMockQuiz4 from "./components/Mock/wbcs/quiz/module4/wbcs.quiz4";
import TutionPaymentSuccess from "./components/payment-success/tution-fees/tfees-payment-success.component";
import AboutUs from "./routes/Policies/About/about-us.routes";
import PrivacyPolicy from "./routes/Policies/Privacy/privacy-policy.component";
import TutionFeesNew from "./components/TutionFees/tution-fees-new.component";
import WbcsMath from "./components/StudyMaterial/Wbcs/Subjects/Math/wbcs-math.component";
import WbcsGeography from "./components/StudyMaterial/Wbcs/Subjects/Geograpy/wbcs-geography.component";
import WbcsHistory from "./components/StudyMaterial/Wbcs/Subjects/History/wbcs-history.component";
import Testimonial from "./components/testimonials/testimonial.component";
import WbcsGeneralScience from "./components/StudyMaterial/Wbcs/Subjects/GeneralScience/wbcs-general-science.component";
import WbcsPolity from "./components/StudyMaterial/Wbcs/Subjects/Polity/wbcs-polity.component";
import WbcsEconomy from "./components/StudyMaterial/Wbcs/Subjects/Economy/wbcs-economy.component";
import WbcsGi from "./components/StudyMaterial/Wbcs/Subjects/GI/wbcs-gi.component";
import WbcsStaticGK from "./components/StudyMaterial/Wbcs/Subjects/StaticGk/wbcs-static-gk.component";
import WbcsCa from "./components/StudyMaterial/Wbcs/Subjects/CA/wbcs-ca.component";
import WbcsEnglishGrammer from "./components/StudyMaterial/Wbcs/Subjects/English/wbcs-english-grammer.component";
import WbcsEnglishVoc from "./components/StudyMaterial/Wbcs/Subjects/English/wbcs-english-voc.component";
import IasMenu from "./components/StudyMaterial/Wbcs/Subjects/Ias/ias-menu.component";
import WbcsCurrentAffairs from "./components/StudyMaterial/Wbcs/Subjects/Current-Affairs/wbcs-current-affairs.component";
import CoursesFirstCard from "./components/Home/Course/cards/first/first-card.course";
import CoursesSecondCard from "./components/Home/Course/cards/second/second-card.course";
import CoursesThirdCard from "./components/Home/Course/cards/third/third-card.course";
import CoursesForthCard from "./components/Home/Course/cards/forth/forth-card.course";
import CoursesFifthCard from "./components/Home/Course/cards/fifth/fifth-card.course";
import CoursesSixthCard from "./components/Home/Course/cards/sixth/sixth-card.course";
import NotificationPage from "./components/animated-notification/notification-page.component";
import TermsConditionPolicy from "./routes/Policies/Terms/terms-condition.component";
import DisclaimerPolicy from "./routes/Policies/Disclaimer/disclaimer.component";
import ReturnsCancellationPolicy from "./routes/Policies/Returns/returns-cancellation.component";
import ShippingPolicy from "./routes/Policies/Shipping/shipping-delivery.component";

const App = () => {
  // for updating WBCS/Ias collection in database

  // useEffect(()=>{
  //   // addCollectionAndDocuments('courses',courseData);
  //   // addCollectionAndDocuments('ias',IasData);
  //   // addHomeIntroCardsData('introcards',introCardData)
  // },[])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
  }, []);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="mock-test" element={<Mock />} />
          <Route path="mock-test/wbcs-mock-test" element={<Wbcs />} />
          <Route path="mock-test/ias-mock-test" element={<Ias />} />
          <Route path="quiz" element={<Quiz />} />
          <Route path="checkout" element={<Checkout />} />
          <Route
            path="checkout-billing-address"
            element={<CheckoutBillingAddress />}
          />
          <Route path="payment-success" element={<PaymentSuccess />} />
          <Route path='testimonial' element={<Testimonial />} />
          <Route path="wbcs-study-material" element={<WbcsStudyMaterial />} />
          <Route path="wbcs-question-paper" element={<WbcsQuestion />} />
          <Route path="wbcs-answer-key" element={<WbcsAnswerKey />} />
          <Route
            path="mock-test/wbcs-mock-test/quiz1"
            element={<WbcsMockQuiz1 />}
          />
          <Route
            path="mock-test/wbcs-mock-test/quiz2"
            element={<WbcsMockQuiz2 />}
          />
          <Route
            path="mock-test/wbcs-mock-test/quiz3"
            element={<WbcsMockQuiz3 />}
          />
          <Route
            path="mock-test/wbcs-mock-test/quiz4"
            element={<WbcsMockQuiz4 />}
          />
          <Route path="tution-fees" element={<TutionFeesNew />} />
          <Route
            path="tution-fees-payment-confirmation"
            element={<TutionPaymentSuccess />}
          />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="disclaimer-policy" element={<DisclaimerPolicy />} />
          <Route path="terms-conditions" element={<TermsConditionPolicy />} />
          <Route
            path="returns-cancellation-policy"
            element={<ReturnsCancellationPolicy />}
          />
          <Route path="shipping-delivery-policy" element={<ShippingPolicy />} />
          <Route path="wbcs/study-material-math" element={<WbcsMath />} />
          <Route
            path="wbcs/study-material-geography"
            element={<WbcsGeography />}
          />
          <Route path="wbcs/study-material-history" element={<WbcsHistory />} />
          <Route
            path="wbcs/study-material-general-science"
            element={<WbcsGeneralScience />}
          />
          <Route path="wbcs/study-material-polity" element={<WbcsPolity />} />
          <Route path="wbcs/study-material-economy" element={<WbcsEconomy />} />
          <Route path="wbcs/study-material-gi" element={<WbcsGi />} />
          <Route
            path="wbcs/study-material-static-gk"
            element={<WbcsStaticGK />}
          />
          <Route path="wbcs/study-material-ca" element={<WbcsCa />} />
          <Route
            path="wbcs/study-material-english-grammer"
            element={<WbcsEnglishGrammer />}
          />
          <Route
            path="wbcs/study-material-english-vocabulary"
            element={<WbcsEnglishVoc />}
          />
          <Route path="ias-study-material" element={<IasMenu />} />
          <Route path="current-affairs" element={<WbcsCurrentAffairs />} />
          <Route
            path="regular-classroom-course"
            element={<CoursesFirstCard />}
          />
          <Route path="wbcs-weekend-batches" element={<CoursesSecondCard />} />
          <Route
            path="wbcs-correspondence-courses"
            element={<CoursesThirdCard />}
          />
          <Route path="test-series" element={<CoursesForthCard />} />
          <Route path="online-wbcs-coaching" element={<CoursesFifthCard />} />
          <Route
            path="wbcs-consultant-guidance"
            element={<CoursesSixthCard />}
          />
          <Route
            path="wbcs-consultant-guidance"
            element={<CoursesSixthCard />}
          />
          <Route path="latest-notification" element={<NotificationPage />} />
        </Route>
      </Routes>
    </Fragment>
  );
};

export default App;
