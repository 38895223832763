import { CART_ACTION_TYPES } from "./cart.types";

export const CART_INITIAL_STATE = {
    cartItem : [],
    isCartOpen : false
}

export const cartReducer = (state=CART_INITIAL_STATE,action={})=>{
    const {type,payload} = action;

    switch(type) {
        
        case CART_ACTION_TYPES.SET_IS_CART_OPEN :
            return {...state,isCartOpen: payload}

        case CART_ACTION_TYPES.SET_CART_ITEMS : 
            return {...state, cartItem: payload}    
    
        default : 
            return state;
    
        }

}