import { Typography, Box, Container, Stack } from "@mui/material";

const NotificationCards = ({ courseData }) => {
  const { title, description, date, url } = courseData;

  return (
    <Container>
      <Stack spacing={2} sx={{ marginTop: "20px" }}>
        <Typography variant="h4" component="div">
          {title}
        </Typography>
        <Typography variant="body1" component="div">
          {date}
        </Typography>
        <Typography variant="body1" component="div">
          {description}
        </Typography>
        <Typography variant="body1" component="div">
           Link :{" "}
          <a href={url} target="_blank">
            {url}
          </a>
        </Typography>
      </Stack>
    </Container>
  );
};

export default NotificationCards;
