import { CATEGORIES_ACTION_TYPES } from "./categories.types";

export const CATEGORIES_INITIAL_STATE = {
    categories : [],
    categoriesTitle : {},
    error: null
}

export const categoriesReducer = 
(state = CATEGORIES_INITIAL_STATE, action={})=>{

    const {type,payload} = action;
    switch(type) {
        case CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_START :
            return { ...state }
        case CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS:
            return {...state,categories: payload}
        case CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_FAILED:
            return {...state, error: payload}
        case CATEGORIES_ACTION_TYPES.FETCH_CATEGORIESTITLE_START:
            return {...state}
        case CATEGORIES_ACTION_TYPES.FETCH_CATEGORIESTITLE_SUCCESS:
            return {...state, categoriesTitle: payload}      
        case CATEGORIES_ACTION_TYPES.FETCH_CATEGORIESTITLE_FAILED:
            return {...state, error: payload }      
        default : 
            return state;            
    }


}