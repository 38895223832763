import { takeLatest, put, all, call } from 'redux-saga/effects';

import { USER_ACTION_TYPES } from "./user.types";

import {
    signInSuccess,
    signInFailed,
    signUpFailed,
    signOutSuccess,
    signOutFailed,
    signUpSuccess
  } from './user.action';

  import {
    getCurrentUser,
    createUserDocumentFromAuth,
    signOutUser,
    signInwithEmail,
    GoogleLoginWithPopup,
    FacebookLoginWithPopup,
    GoogleLoginWithRedirect,
    getRedirectResultFromAuth,
    createUserwithEmail
  } from '../../utils/firebase/firebase.utils';

  export function* getSnapshotFromUserAuth(userAuth, additionalDetails) {
    try {
      const userSnapshot = yield call(
        createUserDocumentFromAuth,
        userAuth,
        additionalDetails
      );
      yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
    } catch (error) {
      yield put(signInFailed(error));
    }
  }

  export function* isUserAuthenticated() {
    try {
      const userAuth = yield call(getCurrentUser);
      if (!userAuth) return;
      yield call(getSnapshotFromUserAuth, userAuth);
    } catch (error) {
      yield put(signInFailed(error));
    }
  }

  export function* onCheckUserSession() {
    yield takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
  }

  export function* signOut(){
    try {
        yield call(signOutUser);
        yield put(signOutSuccess());
      } catch (error) {
        yield put(signOutFailed(error));
      }

  } 
  
  export function* onSignOutStart() {
    yield takeLatest(USER_ACTION_TYPES.SIGN_OUT_START, signOut);
  }

  export function* signInEmail({ payload: { email, password } }) {

    try {
      const { user } = yield call(
        signInwithEmail,
        email,
        password
      );
      yield call(getSnapshotFromUserAuth, user);
    } catch (error) {
      yield put(signInFailed(error.code));
      
    }
  }

  export function* onEmailSignInStart() {
    yield takeLatest(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, signInEmail);
  }

  export function* signInGooglePopup() {

    try {
      const {user} = yield call(GoogleLoginWithPopup);
      yield call(getSnapshotFromUserAuth, user);

    }
    catch(error) {
      yield put(signInFailed(error.code));
    }
  }


  export function* onGoogleSignInWithPopup() {
    yield takeLatest(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START, signInGooglePopup)
  }

  //Sign In with Google Redirect

  export function* signInGoogleRedirect() {

    try {
      yield call(GoogleLoginWithRedirect);
      const {user} = yield call(getRedirectResultFromAuth);
      yield call(getSnapshotFromUserAuth, user);

    }
    catch(error) {
      yield put(signInFailed(error.code));
    }
  }


  export function* onGoogleSignInWithRedirect() {
    yield takeLatest(USER_ACTION_TYPES.GOOGLE_SIGN_IN_START_REDIRECT, signInGoogleRedirect)
  }

  export function* signInFacebookPopup() {

    try {
      const {user} = yield call(FacebookLoginWithPopup);
      yield call(getSnapshotFromUserAuth, user);

    }
    catch(error) {
      yield put(signInFailed(error.code));
    }
  }


  export function* onFacebookSignInWithPopup() {
    yield takeLatest(USER_ACTION_TYPES.FACEBOOK_SIGN_IN_START, signInFacebookPopup)
  }


  export function* signUp({payload: {email, password, displayName,firstname,lastname}}) {
    try {
      const {user} = yield call(createUserwithEmail,email,password)
      yield put(signUpSuccess(user,{displayName,firstname,lastname}))
    }
    catch(error){
      yield put(signUpFailed(error.code));

    }
    


  }

  export function* signInAfterSignUp({ payload: { user, additionalDetails } }) {
    yield call(getSnapshotFromUserAuth, user, additionalDetails);
  }
  

  export function* onSignInAfterSignUp(){
    yield takeLatest(USER_ACTION_TYPES.SIGN_UP_SUCCESS, signInAfterSignUp);
  }

  export function* onSignUpWithEmail() {
    yield takeLatest(USER_ACTION_TYPES.SIGN_UP_START, signUp);
  }



  export function* userSagas(){
      yield all([
        call(onCheckUserSession),
        call(onSignOutStart),
        call(onEmailSignInStart),
        call(onGoogleSignInWithPopup),
        call(onFacebookSignInWithPopup),
        call(onGoogleSignInWithRedirect),
        call(onSignUpWithEmail),
        call(onSignInAfterSignUp)

      ])
  }
  
  


