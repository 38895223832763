import { Fragment, useEffect } from "react";
import { Button,Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Footer/footer.component";
import './login.style.css';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { emailSignInStart,googleSignInStart,facebookSignInStart,googleSignInStartWithRedirect } from '../../store/user/user.action';
import { useDispatch,useSelector} from 'react-redux';
import {selectCurrentUser,selectError} from '../../store/user/user.selector';

import { selectCartItems } from '../../store/cart/cart.selector';

import { addItemToCartFromLogin } from "../../store/cart/cart.action";

import { setCartItemsToDatabase,getCartItemsFromUserDatabase } from "../../utils/firebase/firebase.utils";

import {Spinner} from 'react-bootstrap';

import { Helmet } from "react-helmet-async";


const Login = ()=>{

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const currentUser = useSelector(selectCurrentUser);

    const errorCode = useSelector(selectError);

    const cartItems = useSelector(selectCartItems);

    const [showSpinner,setShowSpinner] = useState(false);


    

    useEffect(()=>{
        if(!currentUser) return;  
        setShowSpinner(false);

        const getCartItemfromDatabase =async()=>{
            const cartItemsFromUser = await getCartItemsFromUserDatabase(currentUser.id);
                
            if(cartItems.length>0 && cartItemsFromUser.length>0){
                const databaseCart = [...cartItemsFromUser];
                const matchedCart = [];
                const unMatchedCartItems = cartItems.filter(({ id: id1 }) => !cartItemsFromUser.some(({ id: id2 }) => id1 === id2));
            
               
                cartItems.filter((obj1) => 
                  cartItemsFromUser.some((obj2) =>{
                     if(obj1.id === obj2.id) {
                       const m = {...obj2,quantity:obj2.quantity+obj1.quantity}
                        // cart.length = 0;
                        matchedCart.push(m);
                     }          
                                            
                 }
                 ));

                 if(unMatchedCartItems.length===0){
                    var restCartItem = 
                    databaseCart.filter((obj1)=> !matchedCart.some((obj2)=>obj1.id===obj2.id))


                } 
                
                console.log("rest cart items", restCartItem);
                console.log("matched cart items", matchedCart);
                console.log("unmatched cart items", unMatchedCartItems);
                if(unMatchedCartItems.length===0){
                    var finalCart = restCartItem.concat(matchedCart)
                }
                else {
                    var finalCart = unMatchedCartItems.concat(matchedCart);
                }
                
                
                
                console.log("final cart items", finalCart)
                dispatch(addItemToCartFromLogin(finalCart));
                setCartItemsToDatabase(currentUser.id,finalCart);
                }

            else if(cartItemsFromUser.length>0) {

                dispatch(addItemToCartFromLogin(cartItemsFromUser));
            }   
            
            else {
                
                setCartItemsToDatabase(currentUser.id,cartItems);

            }
               
               
                
            
            }
            getCartItemfromDatabase();
            
            navigate('/');

        

         if(errorCode){
            handleError(errorCode);
        }
        

    },[currentUser,errorCode])



    const handleError = (errorCode)=>{
        if (errorCode==='auth/wrong-password'){
            alert('Login Failed! Check your email & password combination');
            
        }

        else if (errorCode==='auth/user-not-found'){
            alert('User not Exist! Please create an account')
        }


    }

   
  
    const defaultFields = {
        email :'',
        password: ''
    }

    const [field,setField]= useState(defaultFields);
    const {email,password} = field;

    
    const resetFormField = ()=>{
        setField(defaultFields);
    }

    const handleFieldValue = (event)=>{
        const{name,value} = event.target;
        setField({...field,[name]:value});
    }

    const handleSubmit = (event)=>{
            setShowSpinner(true);
            event.preventDefault();
            dispatch(emailSignInStart(email,password));
            resetFormField();
                               
            }
            

    const handleGoogleSignIn = () =>{
       dispatch(googleSignInStartWithRedirect());
       
    }

    const handleFacebookSignIn = () =>{
        dispatch(facebookSignInStart());
 
     }

    return (
        <Fragment>
            <Helmet>
                <title> Login | Sign in to Your Account</title>
                <meta name="description" content="You can login to your ahguide account using email and password or login using your google account." />        
                <link rel="canonical"  href="https://www.ahguide.in/login" />
            </Helmet>
        <div className="login-container">
            <Form className="form-container" onSubmit={handleSubmit}>
                <p>Sign in with</p>
                <Button onClick={handleGoogleSignIn} variant="light"><i className="fa-brands fa-google"></i> Google</Button>
                <hr />
                <p>Or sign in with credentials</p>
            <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control onChange={handleFieldValue} type="email" placeholder="Enter email" value={email} name='email' />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control onChange={handleFieldValue} type="password" placeholder="Password" value={password} name='password' />
        </Form.Group>
        {showSpinner ?  <Spinner animation="border"variant="danger"/>
                     : <Button variant="danger" type="submit">
                        Sign In
                       </Button> }
       
        <div>Don't have an account? <Link to='/signup'>Sign Up</Link></div>
        </Form>  
        
</div>
<Footer />
</Fragment>


        

    )
}

export default Login;