import { Fragment, useEffect,useState } from "react";
import { Card,Row,Col } from "react-bootstrap";
import './cards.style.css'
import { getHomeIntroData,getHomeIntroTitleFromDatabase,
  getHomeIntroSubHeaderFromDatabase,
  getHomeIntroDescriptionFromDatabase } from "../../../utils/firebase/firebase.utils";
 
 
const localCardData = [
    {
      title : 'Experienced Guide',
      text : 'Best WBCS coaching centre in Birbhum with expert team of faculties and trainers.',
      fontAwesome : "fa-solid fa-user-tie fa-3x"

    },
    {
      title : 'Finest Study Materials',
      text : 'Get study material which will save you thousands of hours in your preparation.',
      fontAwesome : "fa-solid fa-book fa-3x"

    },
    {
      title : 'Regular Test Series & Doubt Sessions',
      text : 'We conduct regular test series.',
      fontAwesome : "fa-solid fa-desktop fa-3x"

    },
    {
      title : 'Have A Question?',
      text : "Join Top WBCS Coaching Institute for WBCS Prelims, Mains Exams & Presonality Test.",
      fontAwesome : "fa-solid fa-circle-question fa-3x"

    }
  ]

const Cards = ()=>{

  const [cardData,setCardData] = useState(localCardData);

  const [introTitle,SetIntroTitle] = useState('A H GUIDE');

  const [introSubHeader,SetIntroSubHeader] = useState('Technical WBCS Coaching in West Bengal');

  const [introDescription,SetIntroDescription] = useState('A H Guide offers effective WBCS courses and to the point study materials to prepare Civil Services exam. This coaching Institute focuses more on practical based preparation rather than mere studying and provides uncountable tests.');

  useEffect(()=>{
    const getIntroData = async()=>{
      const introData = await getHomeIntroData('introcards');
      const introTitle = await getHomeIntroTitleFromDatabase();
      const introSubHeader = await getHomeIntroSubHeaderFromDatabase();
      const introDescription = await getHomeIntroDescriptionFromDatabase();
      SetIntroTitle(introTitle);
      SetIntroSubHeader(introSubHeader);
      SetIntroDescription(introDescription);
      setCardData(introData);      
    }

    getIntroData();

  },[])

  


    return (
      <Fragment>
        <div className="card-container">
        <div style={{textAlign:'center'}}>
          <h1 style={{fontWeight:'bold'}}>{introTitle}</h1>
          <h2>{introSubHeader}</h2>
          <p>{introDescription}</p>
        </div>
      
      <Row xs={1} md={2} className="g-4">
  {
    cardData.map((card)=>{
      const {title,text,fontAwesome} = card;
      return (
      <Col key={title}>
      <Card border="light" className='card-component' bg="light">
        {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
        <Card.Body>
          <div style={{display:'flex',columnGap:'5px'}}>
            <i className={fontAwesome}></i>
            <div>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              {text}
            </Card.Text>
            </div>
            
          </div>
        
        </Card.Body>
      </Card>
    </Col>
      )
    })
  }
    
    
  
</Row>
</div>
</Fragment>


    )
}

export default Cards;