import { CATEGORIES_ACTION_TYPES } from "./categories.types";

import { createAction } from "../../utils/reducer/reducer.utils";

export const fetchCategoriesStart = (categoryName)=>createAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_START,categoryName);

export const fetchCategoriesSuccess = (categoriesArray)=>createAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS,categoriesArray);

export const fetchCategoriesFailed = (error) =>
  createAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_FAILED, error);

export const fetchTitleStart = (categoryTitle)=> createAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIESTITLE_START,categoryTitle);

export const fetchTitleSuccess= (categoriesTitle)=>createAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIESTITLE_SUCCESS,categoriesTitle);

export const fetchTitleFailed = (error)=>createAction(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIESTITLE_FAILED, error);