import { Typography,Box } from "@mui/material";
import { useState,useEffect, Fragment } from "react";
import { getNotificationDataUpdate } from '../../utils/firebase/firebase.utils';
// import CoursesBlog from "../Blog/courses-blog.component";
import NotificationCards from "./notification-cards";

import { Helmet } from "react-helmet-async";

const NotificationPage = ()=>{

    const [courseData,setCourseData] = useState([]);

    useEffect(()=>{
        const getData = async()=>{
            const arrayData = await getNotificationDataUpdate();
            const{data} = arrayData;
            setCourseData(data.reverse());
        
        }

        getData();
        
        
    },[])




    return(
        <Fragment>
            <Helmet>
                <title>Notifications : Latest Updates from Ahguide</title>
                <meta name="description" content="latest notifications about updates and news from ahguide. Ahguide notifications list all updates abou wbcs." />        
                <link rel="canonical" href="https://www.ahguide.in/wbcs/latest-notification" />
            </Helmet>
        <Box>
            
            <Typography sx={{textAlign:"center",marginTop:'20px'}} variant="h3" component="div">
                Notification
            </Typography>
            {
                courseData.map((data)=>{
                    return  <NotificationCards key={data.title} courseData= {data}  />
                })
            }

        </Box>
        </Fragment>
    )

}

export default NotificationPage;
