import { TextField,Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SearchNew =()=>{

    var count = 0;

    const navigate = useNavigate();

    const arrOfCategories = [
      'mocktest',
      'currentaffairs',
      'englishlearning',
      'liveclasses',
      'wbcs',
      'ias',
      'studymaterials'
    ]

   
    const searchHandler = (event) =>{
      count++;
      if(event.key==='Backspace'){
        count--;
      }

      const searchData = event.target.value;
      const categoryFilter = arrOfCategories.filter((category)=>{
        return category.includes(searchData.toLowerCase());
        
      })
      console.log(categoryFilter)
      if (categoryFilter.length>1){
        var mockTest = categoryFilter[1];
      }
      else {
        mockTest = categoryFilter[0];
      }
      
    if(count>2 && event.key==='Enter') {
      count = 0;
      switch(mockTest) {
        
        case 'mocktest' : 
          console.log('Switch Case : mocktest')
          navigate('/mock-test');
          break;
        case 'currentaffairs' : 
         console.log('Switch Case : currentaffairs')  
         break;
        case 'englishlearning' : 
         console.log('Switch Case : englishlearning')
         break;
        case 'liveclasses' : 
         console.log('Switch Case : liveclasses')
         break;
        case 'wbcs' : 
        navigate('/mock-test/wbcs-mock-test');
         console.log('Switch Case : wbcs');
         break; 
        case 'ias' : 
         console.log('Switch Case : ias');
         navigate('/mock-test/ias-mock-test');
         break;
        case 'studymaterials' : 
         console.log('Switch Case : studymaterials');
         break;    
        default : 
         console.log('other')  
      }
    }
      
    }

    
    
    return (
        <Box component="form" onKeyDown={searchHandler}>
        <TextField
          label="Search"
          id="outlined-size-small"
          size="small"
          type="search"
        />       
       </Box>
    )
}

export default SearchNew;