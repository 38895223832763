import './wbcs-question.style.css';

import question from '../../../../assets/wbcs/question/wbcs_prelims_2022.pdf'

const WbcsQuestion = ()=>{
    return (
        <div className='wbcs-question-paper'>

            <p>WBCS Prelims Question Papers 2022 is shared here along with the exam analysis of the exam. Candidates who had appeared for the exam can download the question papers PDF from the link. All shifts question papers are provided here. Candidates who are preparing for the exam can also check the latest exam level from the papers.</p>

            <p>Practicing the previous year's papers shall prove to be of utmost importance to all the candidates as you get to know the variety of questions that are asked in the exam. Candidates preparing for the West Bengal Civil Service Exam must download the question papers and solve them. Below you can download the latest as well as previous year's question papers. The direct link to download the prelims and mains WBCS Question Papers pdf is given below for the Prelims and Mains exam.</p>

            <h3>WBCS Question Paper 2022</h3>

            <p>WBCS Question Paper 2022 for prelims has been made available for all shifts. We have provided you with the WBCS Prelims Question Paper for the exam that was held on June 19, 2022. Hence, the candidates should practice the question papers to boost their preparations and work on the key improvement areas.</p>

            <h5>WBCS Prelims Question Paper 2022 - <a href={question} download>Download Set C Pdf</a></h5>
        </div>
    )
} 

export default WbcsQuestion;