import {Row} from 'react-bootstrap';
import './ias.style.css';
import { useEffect,Fragment } from 'react';

import ProductCard from '../../Product-card/product-card.component';

import { useDispatch,useSelector } from 'react-redux';

import { fetchCategoriesStart,fetchTitleStart } from '../../../store/categories/categories.action';

import { selectCategories,selectTitle } from '../../../store/categories/categories.selector';

import GrowSpinner from '../../Spinner/grow-spinner/grow-spinner.component';

import { Helmet } from "react-helmet-async";

const Ias = ()=>{

    const dispatch = useDispatch();

    const card = useSelector(selectCategories);
 
    const header = useSelector(selectTitle);
 
    useEffect(()=>{
 
         dispatch(fetchTitleStart('ias'));
         dispatch(fetchCategoriesStart('ias'));
        
 
    },[])
   
    return (

        <Fragment>

            <Helmet>
                <title>IAS Mock Test Free | IAS Test Series | IAS Online Tests</title>
                <meta name="description" content="Start with free IAS mock test for IAS Prelims exam preparation. Attempt IAS online test series to clear the exam with high score." />        
                <link rel="canonical" href="https://www.ahguide.in/mock-test/ias-mock-test" />
            </Helmet>

        <div className='ias-container'>
            <div style={{display:'flex',justifyContent:'center'}}>
            {
                
                (Object.keys(header).length === 0) ? <GrowSpinner /> 
                         : <h2 style={{marginBottom:'20px'}}>{header.title}</h2>
                
            }
            </div>
            

            <Row xs={1} md={4} className="g-4">
               {
                   card.map((item)=>{
                       return <ProductCard key={item.title} product={item} />
                   })
               }
                


                
            
            
            
            </Row>                          
          
            

        </div>
        </Fragment>
    )

 

}

export default Ias;