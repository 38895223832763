import { Fragment, useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOutStart } from "../../store/user/user.action";
import { clearAllCartItems } from "../../store/cart/cart.action";
import "./navigation.style.css";
import logo from "../../assets/logo/ahguide.png";
import CartIcon from "../Cart-icon/cart.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useSelector } from "react-redux";
import { selectIsCartOpen } from "../../store/cart/cart.selector";

import CartDropdown from "../CartDropDown/cart-dropdown.component";
import SearchNew from "../Search/search-new.component";
import {
  IconButton,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
  Menu,
  MenuItem,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet-async";
const Navigation = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const user = useSelector(selectCurrentUser);

  const isCartOpen = useSelector(selectIsCartOpen);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const signOutHandler = () => {
    dispatch(signOutStart());
    navigate("/");
    dispatch(clearAllCartItems());
  };

  const open = Boolean(anchorEl);

  const handleSideBar = () => {
    setAnchorEl(null);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Fragment>
      <Helmet>
        <title>
          Best WBCS Coaching Online | WBCS Exam Preparation Institute | Ahguide
        </title>
        <meta
          name="description"
          content="Ahguide provides the best coaching in birbhum for WBCS and IAS. Offering advanced education, study materials, practice and doubt solving sessions and mock tests."
        />
        <link rel="canonical" href="https://www.ahguide.in" />
      </Helmet>
      <Navbar variant="light" bg="warning" expand="sm">
        <Container>
          <Navbar.Brand>
            {/* <img 
    src={logo} 
    alt='ahguide'
    width="10"
    height="10"
    className="d-inline-block align-top"
    />{' '} */}
            <Link to="/" className="nav-link2">
              AHGUIDE
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className="me-auto"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link>
                <Link to="/" className="nav-link2">
                  Home
                </Link>
              </Nav.Link>
              <NavDropdown title="Study Materials" id="navbarScrollingDropdown">
                <NavDropdown.Item onClick={handleSideBar}>
                  WBCS
                </NavDropdown.Item>
                <NavDropdown.Item href="/ias-study-material">
                  IAS
                </NavDropdown.Item>
                <NavDropdown.Item href="/current-affairs">
                  Current Affairs
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>
                <Link to="mock-test" className="nav-link2">
                  Mock Test
                </Link>
              </Nav.Link>
              <NavDropdown
                title="English Learning"
                className="nav-link2"
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item href="/wbcs/study-material-english-grammer">
                  Grammer
                </NavDropdown.Item>
                <NavDropdown.Item href="/wbcs/study-material-english-vocabulary">
                  Vocabulary
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>
                <Link to="/tution-fees" className="nav-link2">
                  Tution Fees
                </Link>
              </Nav.Link>
              {/* <Nav.Link href="#" disabled>
        Link
      </Nav.Link> */}
            </Nav>
            <Nav>
              {/* <div className="d-flex"> */}
              <Stack direction="row" spacing={2}>
                <SearchNew />

                {user ? (
                  <Button onClick={signOutHandler} variant="outlined">
                    Logout
                  </Button>
                ) : (
                  <Link className="link" to="/login">
                    <Button variant="outlined" size="large">
                      Login
                    </Button>
                  </Link>
                )}

                <CartIcon />
                {isCartOpen && <CartDropdown />}
              </Stack>
            </Nav>
          </Navbar.Collapse>
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            ModalProps={{
              keepMounted: true,
            }}
            onClose={handleCloseDrawer}
          >
            <Box p={2} width="300px" role="presentation" textAlign="center">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="drawer close button"
                onClick={handleCloseDrawer}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                onClick={() => setIsDrawerOpen(false)}
                variant="h5"
                component="div"
                color="primary"
              >
                <Link to="/wbcs-study-material" className="nav-link">
                  WBCS
                </Link>
              </Typography>
              <hr />
              <Stack direction="column" spacing={1}>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link to="/wbcs/study-material-math" className="nav-link">
                    Math
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link
                    to="/wbcs/study-material-geography"
                    className="nav-link"
                  >
                    Geography
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link to="/wbcs/study-material-history" className="nav-link">
                    History
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link
                    to="/wbcs/study-material-general-science"
                    className="nav-link"
                  >
                    General Science
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link to="/wbcs/study-material-polity" className="nav-link">
                    Polity(Indian Constitution)
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link to="/wbcs/study-material-economy" className="nav-link">
                    Economy
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link to="/wbcs/study-material-gi" className="nav-link">
                    GI
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link
                    to="/wbcs/study-material-static-gk"
                    className="nav-link"
                  >
                    Static GK
                  </Link>
                </Typography>
                <Typography
                  onClick={() => setIsDrawerOpen(false)}
                  variant="h5"
                  component="div"
                >
                  <Link to="/wbcs/study-material-ca" className="nav-link">
                    CA
                  </Link>
                </Typography>
              </Stack>
            </Box>
          </Drawer>
        </Container>
      </Navbar>

      <Outlet />
    </Fragment>
  );
};

export default Navigation;
