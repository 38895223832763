import { loadScript } from "../../../utils/razorpay/razorpay-script";
import { postTutionPaymentDetails } from "../../../utils/firebase/firebase.utils";

export const tutionFeesPaymentHandler = async (formFields) => {
  const { name, email, mobile, amount, address } = formFields;

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  // Getting order instance

  const response = await fetch("/.netlify/functions/create-order-instance", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ amount: amount * 100 }),
  }).then((res) => res.json());

  if (!response) {
    alert("Server error. Are you online?");
    return;
  }

  // Getting the order details back
  const { id } = response.orderInstance;

  const options = {
    key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
    amount: amount.toString(),
    currency: "INR",
    name: "A H Guide Tution Fees",
    description: "Tution Fee Payment",
    // image: { logo },
    order_id: id,
    handler: async function (orderData) {
      const data = {
        orderCreationId: id,
        razorpayPaymentId: orderData.razorpay_payment_id,
        razorpayOrderId: orderData.razorpay_order_id,
        razorpaySignature: orderData.razorpay_signature,
      };

      const successResponse = await fetch(
        "/.netlify/functions/razorpay-payment-success",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data }),
        }
      ).then((res) => res.json());

      if (successResponse) {
        const orderno = await postTutionPaymentDetails(
          formFields,
          data.razorpayOrderId
        );

        document.location =
          "https://www.ahguide.in/tution-fees-payment-confirmation";
      }

      // postOrderDetails(formFields,data.razorpayOrderId)

      // const result = await axios.post("http://localhost:5000/payment/success", data);
    },
    prefill: {
      name: name,
      email: email,
      contact: mobile,
    },
    notes: {
      address: address,
    },
    theme: {
      color: "#ffc107",
    },
  };

  const paymentObject = new window.Razorpay(options);

  paymentObject.on("payment.failed", async function (orderfailed) {
    const failedData = {
      code: orderfailed.error.code,
      description: orderfailed.error.description,
      source: orderfailed.error.source,
      step: orderfailed.error.step,
      reason: orderfailed.error.reason,
      order_id: orderfailed.error.metadata.order_id,
      payment_id: orderfailed.error.metadata.payment_id,
    };

    const paymentFailed = await fetch(
      "/.netlify/functions/razorpay-payment-failed",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ failedData }),
      }
    ).then((res) => res.json());

    if (paymentFailed) {
      alert(failedData.reason);
    }
  });

  paymentObject.open();

  return options.handler;
};
