import { CATEGORIES_ACTION_TYPES } from "./categories.types";

import { getCourseData,getCourseTitle } from "../../utils/firebase/firebase.utils";

import { takeLatest, all, call, put } from 'redux-saga/effects'; 

import {
    fetchCategoriesSuccess,
    fetchCategoriesFailed,
    fetchTitleSuccess,
    fetchTitleFailed
} from '../categories/categories.action'

export function* fetchCategories({payload: categoryName}){
    try {
      const cardsData = yield call(getCourseData,categoryName);
      yield put(fetchCategoriesSuccess(cardsData));

    }
    catch(error){
        yield put(fetchCategoriesFailed(error))

    }
    

}


export function* onFetchCategories(){
    yield takeLatest(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_START, fetchCategories)
}

export function* fetchTitle({payload:categoryTitle}){
    try {
        const courseTitle = yield call(getCourseTitle,categoryTitle);
        yield put(fetchTitleSuccess(courseTitle))

    }
    catch(error){
        yield put(fetchTitleFailed(error));

    }
}

export function* onFetchTitle() {
   yield takeLatest(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIESTITLE_START, fetchTitle)
}

export function* categoriesSaga() {
    yield all([call(onFetchCategories),
              call(onFetchTitle)])
} 