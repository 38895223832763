import {Carousel} from 'react-bootstrap';
import slider2 from '../../assets/slider/ahguide-wbcs.jpg';
import { useEffect, useState} from 'react';

import { downloadImage } from '../../utils/firebase/firebase.utils';

const Slider = () => {

  const [url,setUrl] = useState(slider2);

  useEffect(()=>{

    const sliderImgUrl = async()=>{
      const sliderImage = await downloadImage();
      setUrl(sliderImage);
    }

    sliderImgUrl();

  },[])



  return (
     
<Carousel variant="dark">
        
        <Carousel.Item interval={500}>
         <a href='/wbcs-study-material'>
         <img
            className="d-block w-100"
             src={url}
            alt="A H Guide Coaching "
          />
          </a> 
          {/* <Carousel.Caption>
            <h3>Label for second slide</h3>
            <p>Sample Text for Image Two</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      
        
    )
}

export default Slider;