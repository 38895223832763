import { useState, useEffect } from "react";
import {
  getFooterTitleDescription,
  getFooterContactDetails,
} from "../../utils/firebase/firebase.utils";
import { IconButton, Paper, Stack, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import "./footer.style.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const defaultContacFields = {
    title: "A H Guide",
    address1: "New Bus Stand,Rampurhat",
    address2: "W.B-731224",
    mobile: "Mobile: 7098230513",
  };

  const [footerTitle, SetFooterTitle] = useState("A H Guide");

  const [footerDescription, SetFooterDescription] = useState(
    "Get the Evidence of Success and Testimony of preparation with the Best WBCS Coaching."
  );

  const [footerContactFields, SetFooterContactFields] =
    useState(defaultContacFields);

  useEffect(() => {
    const getFooterItems = async () => {
      const footerItems = await getFooterTitleDescription();
      const footerContactItems = await getFooterContactDetails();
      SetFooterTitle(footerItems.title);
      SetFooterDescription(footerItems.description);
      SetFooterContactFields(footerContactItems);
    };
    getFooterItems();
  }, []);
  return (
    <Paper elevation={2}>
      <div className="footer-container">
        <div>
          <h3>{footerTitle}</h3>
          <p>{footerDescription}</p>
        </div>
        <div className="footer-middle">
          <div>
            <h5 style={{ textAlign: "center" }}>About Us</h5>
            <ul>
              <Link to="/about-us" className="footer-link">
                <li>About us</li>
              </Link>
              <Link to="/contact-us" className="footer-link">
                <li>Contact us</li>
              </Link>
              <Link to="/returns-cancellation-policy" className="footer-link">
                <li>Refund & Cancellation</li>
              </Link>
              <Link to="/shipping-delivery-policy" className="footer-link">
                <li>Shipping & Delivery</li>
              </Link>

              <Link to="/privacy-policy" className="footer-link">
                <li>Privacy Policy</li>
              </Link>
              <Link to="/terms-conditions" className="footer-link">
                <li>Terms & Conditions</li>
              </Link>
              <Link to="/disclaimer-policy" className="footer-link">
                <li>Disclaimer</li>
              </Link>
            </ul>
          </div>
          <div>
            <h5>Our Courses</h5>
            <ul>
              <li>
                <Link
                  to="/wbcs-study-material"
                  style={{ color: "rgba(0, 0, 0, 0.87)" }}
                >
                  WBCS
                </Link>
              </li>
              <li>
                <Link
                  to="/ias-study-material"
                  style={{ color: "rgba(0, 0, 0, 0.87)" }}
                >
                  IAS
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <h5>Contact Us</h5>
          <p>{footerContactFields.title}</p>
          <p>{footerContactFields.address1}</p>
          <p>{footerContactFields.address2}</p>
          <p>{footerContactFields.mobile}</p>
        </div>
      </div>
      <Box
        className="follow-us"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "5px",
        }}
      >
        <Stack direction="row">
          <Typography variant="body1" component="div">
            Follow us:
          </Typography>
          <Box sx={{ display: "flex", gap: "2px" }}>
            <a
              href="https://www.facebook.com/ahguide2018/"
              rel="noopener"
              title="facebook"
              target="_blank"
            >
              <FacebookIcon sx={{ color: "#000" }} />
            </a>

            <a
              href="https://www.instagram.com/ahguide"
              rel="noopener"
              title="instagram"
              target="_blank"
            >
              <InstagramIcon sx={{ color: "#000" }} />
            </a>
            <a href="#" rel="noopener" title="youtube" target="_blank">
              <YouTubeIcon sx={{ color: "#000" }} />
            </a>
            <a href="#" rel="noopener" title="twitter" target="_blank">
              <TwitterIcon sx={{ color: "#000" }} />
            </a>
          </Box>
        </Stack>
        <Stack>
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="body2"
            component="div"
          >
            &#169;2022, AHGUIDE. All rights reserved.
          </Typography>
        </Stack>
      </Box>

      {/* <div className='copyright'>
            <p>Design & Developed by Nazimul Hossain</p>
        </div> */}
    </Paper>
  );
};

export default Footer;
