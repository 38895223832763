import { useState,useRef, Fragment } from "react";
import { Card,Button } from "react-bootstrap";
import { postMockResultsToDatabase } from "../../../../../utils/firebase/firebase.utils";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "../../../../../store/user/user.selector";
const question = [
    {
        question : 'Verification of log-in name and password is for?',
        option1: 'Authenticating the user',
        option2: 'Re-confirming the user',
        option3: 'Providing formal access to the user',
        correct : 'Authenticating the user',
        name: 'fav'
    },

    {
        question: 'USB is which type of storage device?',
        option1: 'Tertiary',
        option2: 'Secondary',
        option3: 'Primary',
        correct: 'Secondary',
        name: 'ide'
    },
    {
        question: 'Storage which stores or retains data after power off is called____',
        option1: 'Volatile storage',
        option2: 'Non-volatile storage',
        option3: 'Direct storage',
        correct: 'Non-volatile storage',
        name : 'nvs'
    }
]


var answerArray = [];

var point = 0;

const WbcsMockQuiz4=()=>{
    
    const [quesNo,setQuesNo] = useState(0);
    const [answer,setAnswer] = useState('');
    const [ansNo,setAnsNo] = useState(0);
    const inputElement1 = useRef();
    const inputElement2 = useRef();
    const inputElement3 = useRef();

    const currentUser = useSelector(selectCurrentUser);

    const navigate = useNavigate();

    const mock1 = 
        {
            answer : answerArray,
            mocktest: 'wbcsmock4'
        }
    
    

    const handleInput=(e)=>{
        
        const answer= e.target.value;
        setAnswer(answer);
    }

    const handleForm = async(e)=>{
        e.preventDefault();
        inputElement1.current.checked = false;
        inputElement2.current.checked = false;
        inputElement3.current.checked = false;
        answerArray.push(answer);
        if(answerArray[ansNo]===question[quesNo].correct)
        {
            point = point + 10;
        }
        
        setQuesNo(quesNo+1);
        setAnsNo(ansNo+1);

        if(quesNo===2) {
            await postMockResultsToDatabase(currentUser.id,mock1,point);
            console.log('updated');
            const response = await fetch('/.netlify/functions/nodemailer-mock-email',{
                method: 'post',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify( {
                        score : point,
                        answer : mock1.answer,
                        mocktest: mock1.mocktest,
                        email: currentUser.email,
                        name : currentUser.firstname + ' ' + currentUser.lastname,
                      } )
                    })
                    
                    if(response) {
                      console.log('Email Sent')
          
                    }
                    else {
                      alert('error')
                    }
        }

        

        

    }

    const handlePrevious = ()=>{
        
        setQuesNo(quesNo-1);
        setAnsNo(ansNo-1);
        answerArray.pop();
        if(point!==0) {
            point = point - 10;
        }

    }

    const handleNavigate = ()=>{
        answerArray = [];
        point= 0;
        navigate('/mock-test/wbcs-mock-test');
    }

    
    return (
        <Fragment>
            {
                quesNo===3 ? 
                <Card style={{textAlign:'center',margin:20,fontSize:30}}>
                <Card.Body>Mock Test Score: {point}</Card.Body>
                <Card.Text><Button onClick={handleNavigate} variant="warning">Try Again</Button></Card.Text>
              </Card>
                    
                    

                :
        <Card bg='light' style={{margin:20,display:'flex'}}>
        <Card.Header style={{textAlign:'center'}}>Mock Test</Card.Header>
        <Card.Body>
        <form onSubmit={handleForm}>
          <Card.Title>{question[quesNo].question}</Card.Title>
          <Card.Text>
          <label>
                <input type="radio" ref={inputElement1} name={question[quesNo].name} value={question[quesNo].option1} onChange={handleInput} required/>
                &nbsp;&nbsp;{question[quesNo].option1}</label><br/>
                <label>
               <input type="radio" ref={inputElement2} name={question[quesNo].name} value={question[quesNo].option2} onChange={handleInput} required/>
               &nbsp;&nbsp;{question[quesNo].option2}</label><br/>
               <label>
               <input type="radio" ref={inputElement3} name={question[quesNo].name} value={question[quesNo].option3} onChange={handleInput} required/>
               &nbsp;&nbsp;{question[quesNo].option3}</label><br/><br/>
               {
                quesNo===0 ? <Button variant="warning" type="submit">Next</Button>

                : <Fragment>
                <Button variant="warning"  onClick={handlePrevious}>Previous</Button>{' '}
                <Button variant="warning"  type="submit">Next</Button>
                </Fragment>
               }
          </Card.Text>
          </form>
        </Card.Body>
      </Card>
}
        
        </Fragment>
    )
}

export default WbcsMockQuiz4;