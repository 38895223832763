import React from 'react';
import Slider from '../components/Slider/slider.component';
import Cards from '../components/Home/Intro/cards.component';
import Contact from '../components/Contact/contact.component'
import Course from '../components/Home/Course/course.component';
import AnimatedNotification from '../components/animated-notification/animated-notification.component';
import Testimonial from '../components/testimonials/testimonial.component';

const Home = () => {
  return (
    <>
   
 <AnimatedNotification />   
 <Slider />
 
 <div style={{marginTop:'10px'}}>
    <Cards />
 </div>
 <Course />
 {/* <Course /> */}
 <Testimonial />
 <Contact />

 
    
  
</>
  )
  
  }

export default Home;