import { Link } from 'react-router-dom';

import {Button, Card,Col} from 'react-bootstrap';

import { useDispatch,useSelector } from 'react-redux';

import { addItemToCart } from '../../store/cart/cart.action';

import { selectCartItems } from '../../store/cart/cart.selector';

import {selectCurrentUser} from '../../store/user/user.selector';

import { setCartItemsToDatabase,getCartItemsFromUserDatabase } from "../../utils/firebase/firebase.utils";

import { useEffect, useState } from 'react';

import GrowSpinner from '../Spinner/grow-spinner/grow-spinner.component';

import { useNavigate } from 'react-router-dom';

import AlertPop from '../Feedbacks/alert.component';

const ProductCard = ({product})=>{

    const navigate = useNavigate();
    
    const {title,expires,maxMarks,questions,time,id} = product;

    const dispatch = useDispatch();

    const cartItems = useSelector(selectCartItems);

    const currentUser = useSelector(selectCurrentUser);

    const [showSpinner,setShowSpinner] = useState(false);

   

    useEffect(()=>{
        setShowSpinner(false);
        if(!currentUser) return;
        setCartItemsToDatabase(currentUser.id,cartItems);


    },[currentUser,cartItems])


    const handleAddtoCart=()=>{
        setShowSpinner(true);
        dispatch(addItemToCart(cartItems,product));
        navigate('/checkout');
        
    }

    const handleAttempt = (e)=>{
        if(currentUser) {
            switch(id){
                case 'wbcsitem1' : 
                    navigate('/mock-test/wbcs-mock-test/quiz1');
                break;
                case 'wbcsitem2':
                    navigate('/mock-test/wbcs-mock-test/quiz2');
                    break;
                case 'wbcsitem3':
                        navigate('/mock-test/wbcs-mock-test/quiz3');
                        break;
                case 'wbcsitem4':
                        navigate('/mock-test/wbcs-mock-test/quiz4');
                        break;            
                default:
                    navigate('/mock-test/wbcs-mock-test/');
                    break;

            }
            
        }
        else {
            alert('Please Login to Attempt');
            navigate('/login')
        }

    }

    return (
        <Col>
        <Card className='ias-cards'>
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
               Expires : {expires}
               Max Marks : {maxMarks}
               Questions: {questions}
               Time : {time}
               
           </Card.Text>
           
        </Card.Body>
        <div>
            {
                showSpinner ? <GrowSpinner />
                            : <Button onClick={handleAddtoCart} variant="primary">Add to Cart</Button>


            }
            
            <Button onClick={handleAttempt} variant="warning">Attempt</Button>
        </div>
        
    </Card>
    </Col>
    )
}

export default ProductCard;