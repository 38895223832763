import {Row} from 'react-bootstrap';

import './wbcs.style.css';

import { Fragment, useEffect} from 'react';

import { useDispatch,useSelector } from 'react-redux';

import { fetchCategoriesStart,fetchTitleStart } from '../../../store/categories/categories.action';

import { selectCategories,selectTitle } from '../../../store/categories/categories.selector';

import ProductCard from '../../Product-card/product-card.component';

import GrowSpinner from '../../Spinner/grow-spinner/grow-spinner.component';

import { Helmet } from "react-helmet-async";

const Wbcs = ()=>{

   const dispatch = useDispatch();

   const card = useSelector(selectCategories);

   const header = useSelector(selectTitle);



   useEffect(()=>{

    
        dispatch(fetchTitleStart('wbcs'));
        dispatch(fetchCategoriesStart('wbcs'));
       

   },[])
  

    return (
        <Fragment>
            <Helmet>
                <title>WBCS Mock Test Free | WBCS Test Series | WBCS Online Tests</title>
                <meta name="description" content="Start with free WBCS mock test for WBCS Prelims exam preparation. Attempt WBCS online test series to clear the exam with high score." />        
                <link rel="canonical" href="https://www.ahguide.in/mock-test/wbcs-mock-test" />
            </Helmet>
        
        <div className='wbcs-container'>
            
                <div style={{display:'flex',justifyContent:'center'}}>
                   {  (Object.keys(header).length === 0) ? <GrowSpinner /> 
                         : <h2 style={{marginBottom:'20px'}}>{header.title}</h2>
                        }
                </div>
               
            

            <Row xs={1} md={4} className="g-4">
               {
                   card.map((item)=>{           
                       return <ProductCard key={item.title} product={item} />
                   })
               }   
            
            
            </Row>           

        </div>
        </Fragment>
    )

 

}

export default Wbcs;