import { Button,ButtonGroup,Form } from "react-bootstrap";
import Footer from "../../components/Footer/footer.component";
import PaymentForm from "../../components/payment-form/payment-form";
import './checkout-billing-address.styles.css';
import { useState } from "react";

import { useSelector } from "react-redux";

import {selectCartTotal} from "../../store/cart/cart.selector";

import { paymentHandler } from "../../components/payment-form/payment-form";

import { getUserDetailsFromUserDatabase } from "../../utils/firebase/firebase.utils";

import { selectCurrentUser } from "../../store/user/user.selector"; 

import { useEffect } from "react";

import GrowSpinner from '../../components/Spinner/grow-spinner/grow-spinner.component';



const CheckoutBillingAddress = ()=>{

    const [showGrowSpinner,setGrowSpinner] = useState(false);

    const amount = useSelector(selectCartTotal);

    const currentUser = useSelector(selectCurrentUser);

    const defaultData = {
        fullname: '',
        email: '',
        address: '',
        pincode:'',
        mobile:''
    }
    const [formFields,setFormFields] = useState(defaultData);

    const {fullname,email,address,pincode,mobile} = formFields;

    useEffect(()=>{
        if(currentUser) {
            const userData = async()=>{
                const getUserDetails = await getUserDetailsFromUserDatabase(currentUser.id);
                const {firstname,lastname,email} = getUserDetails;
                if(firstname===undefined){
                    var fullname = '';
                }
                else {
                    fullname = firstname + " " + lastname;
                }
                
                setFormFields({...formFields,fullname:fullname,email:email});
            }
            userData();
        }
        
    },[currentUser])


    const handleFormField=(event)=>{
        const {name,value} = event.target;
        setFormFields({...formFields,[name]:value});
    }

    const billingSubmitHandler=async(e)=>{
        e.preventDefault();
        setGrowSpinner(true);
       const response = await paymentHandler(formFields,amount);
       console.log(response)
   
        
        

       
       
    }


    return (
        <div>
         <h1 style={{textAlign:'center',textDecoration:'underline'}}>Billing Details:</h1>   
        <Form onSubmit={billingSubmitHandler} className="checkout-billing">
               
            <Form.Group className="mb-3" controlId="formGroupFirstName">
            <Form.Label>Name</Form.Label>
            <Form.Control onChange={handleFormField} type="text" placeholder="Name" name='fullname' value={fullname} required />
        </Form.Group> 
          
    
        <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control onChange={handleFormField} type="email" placeholder="Enter email" name='email' value={email} required />
        </Form.Group>
    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Address:</Form.Label>
        <Form.Control onChange={handleFormField} as="textarea" rows={3} name='address' value={address} required />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formGroupLastName">
            <Form.Label>Pincode</Form.Label>
            <Form.Control onChange={handleFormField} type="number" placeholder="Enter Pincode" name='pincode' value={pincode} required />
    </Form.Group> 

    <Form.Group className="mb-3" controlId="formGroupLastName">
            <Form.Label>Mobile</Form.Label>
            <Form.Control onChange={handleFormField} type="tel" placeholder="Mobile Number" name='mobile' value={mobile} required/>
    </Form.Group>
    {showGrowSpinner ?  <GrowSpinner />
                       : <Button type="submit" variant="warning" size="ls">Proceed to Payment</Button>

}
        
        </Form>  

        {/* <PaymentForm /> */}
        <Footer />
        </div>
    )

}


export default CheckoutBillingAddress;