import './cart-item.style.css';

const CartItem =({product})=>{

    const {title,quantity,price} = product;



    return (
        
            <div className='cart-item-container'>
                
            <div className='item-details'>
            <p></p>
            <span className='name'>{title}</span>
             <span>
             {quantity} x {price}
            </span>

             </div>
             
            </div>
            

    )
}

export default CartItem;