import * as React from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

export default function SendButton() {
  return (
      <Button type= "submit" variant="contained" color="warning" endIcon={<SendIcon />}>
        Send
      </Button>
  );
}
