import { Typography,Box } from "@mui/material";
import { useState,useEffect } from "react";
import CoursesBlog from "../../../../Blog/courses-blog.component";
import { getCoursesSixthCard } from "../../../../../utils/firebase/firebase.utils";
import { Helmet } from "react-helmet-async";

const CoursesSixthCard = ()=>{

    const [courseData,setCourseData] = useState([]);

    useEffect(()=>{
        const getData = async()=>{
            const arrayData = await getCoursesSixthCard();
            const{data} = arrayData;
            setCourseData(data.reverse());
        
        }

        getData();
        
        
    },[])




    return(
        <>
        <Helmet>
                <title> WBCS Consultant Guidance | Ahguide  </title>
                <meta name="description" content="Confused about WBCS Preparation? Request counselling Now." />        
                <link rel="canonical"  href="https://www.ahguide.in/wbcs-consultant-guidance" />
            </Helmet>
        
        <Box>
            <Typography sx={{textAlign:"center",marginTop:'20px'}} variant="h3" component="div">
                WBCS Consultant Guidance
            </Typography>
            {
                courseData.map((data)=>{
                    return  <CoursesBlog key={data.title} courseData= {data}  />
                })
            }

        </Box>
        </>
    )

}

export default CoursesSixthCard;