import { Container,Box,Card,CardContent,Avatar,Typography,Grid } from "@mui/material";





const TestimonialCard = ({data1,data2})=>{


  const {title,description,url } = data1;
  
    // const firstName = data1.name.split(" ")[0].toLowerCase();
    // const firstName2 = data2.name.split(" ")[0].toLowerCase();

   
   
    // let profileImage =  image.filter((img)=> img.includes(firstName.concat(".jpg")));

    // let profileImage2 =  image.filter((img)=> img.includes(firstName2.concat(".jpg")));
  
  
    return (
        
        <Grid container spacing={2}>
        <Grid item sx={12} md={6}>
        <Card>
    <CardContent sx={{display:'flex',flexDirection:'column',alignItems:'center',gap:'5px',bgcolor:'#f6f6f7'}}>
        <Avatar alt={title} src={url}
         sx={{ width: 200, height: 200}} />
        <Typography variant="h4" component="div" color="#7b1fa2">
            {title}
        </Typography>
        <Typography variant="h5" component="div">
            {description}
        </Typography>
        {/* <Typography variant="body1" component="div">
        <q>{data1.review}</q>
        </Typography> */}

    </CardContent>
        </Card> 
        </Grid>
        <Grid item sx={12} md={6}>
        <Card>
    <CardContent sx={{display:'flex',flexDirection:'column',alignItems:'center',gap:'5px',bgcolor:'#f6f6f7'}}>
        <Avatar alt={data2.title} src={data2.url} sx={{ width: 200, height: 200}} />
        <Typography variant="h4" component="div" color="#7b1fa2">
            {data2.title}
        </Typography>
        <Typography variant="h5" component="div">
            {data2.description}
        </Typography>
        {/* <Typography variant="body1" component="div">
        <q>{data2.review}</q>
        </Typography> */}

    </CardContent>
    </Card>
        </Grid>

    </Grid>
    

        
    )
}

export default TestimonialCard;