import { Helmet } from "react-helmet-async";
import Footer from "../../../components/Footer/footer.component";

const ShippingPolicy = () => {
  return (
    <>
      <Helmet>
        <title> Shipping & Delivery Policy | Ahguide </title>
        <meta
          name="description"
          content="If, for any reason, You are not completely satisfied with a purchase
          We invite You to review our policy on refunds and returns."
        />
        <link
          rel="canonical"
          href="https://www.ahguide.in/shipping-delivery-policy"
        />
      </Helmet>
      <div className="container">
        <h2 style={{ textAlign: "center" }}>Shipping & Delivery</h2>
        <p>
          For International buyers, orders are shipped and delivered through
          registered international courier companies and/or International speed
          post only. For domestic buyers, orders are shipped through registered
          domestic courier companies and /or speed post only. Orders are shipped
          within 0-2 days or as per the delivery date agreed at the time of
          order confirmation and delivering of the shipment subject to Courier
          Company / post office norms. A H GUIDE is not liable for any delay in
          delivery by the courier company / postal authorities and only
          guarantees to hand over the consignment to the courier company or
          postal authorities within 0-2 days from the date of the order and
          payment or as per the delivery date agreed at the time of order
          confirmation. Delivery of all orders will be to the address provided
          by the buyer. Delivery of our services will be confirmed on your mail
          ID as specified during registration. For any issues in utilizing our
          services you may contact our helpdesk on or support@ahguide.in
        </p>
      </div>
      <Footer />
    </>
  );
};
export default ShippingPolicy;
