import './wbcs-overview.style.css';
import { Accordion,Table } from 'react-bootstrap';
import notifications from '../../../assets/wbcs/noifications/wbcs_2022_notofication.pdf';
import { Container } from '@mui/material';
import {Helmet} from "react-helmet-async";

const WbcsOverview = ()=>{

    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>WBCS Study Material : Prelims and Mains Study Plan Online</title>
          <meta name="description" content="West Bengal WBCS Study Material and Plan, Get daily study notes and mock test to clear the WBCS Prelims." />        
          <link rel="canonical" href="https://www.ahguide.in/wbcs-study-material" />
        </Helmet>
        <div className="wbcs-overview-desc">
          <p>
          WBCS 2022 Exam Date For Mains Exam is expected to be announced by the West Bengal Public Service Commission (WBPSC). Candidates who are preparing for the mains exam, waiting for WBCS Results 2022 For Prelims exam conducted on 19th June 2022 across the state in pen paper mode (Offline). As per the WBPSC WBCS 2022 Notification PDF, applicants can appear in the Mains exam, if they are shortlisted in the list released on wbpsc. gov. in.

          </p>

            <p>

          Earlier, West Bengal Public Service Commission (WBPSC) released the official answer key for the prelims exam conducted for various public service posts under the WB state government. Applicants who are preparing for WBCS 2022 exam should check the details mentioned below like WBPSC WBCS 2022 notification, exam date, syllabus, eligibility, age limit, qualification, vacancy, etc. Scroll on!

          </p>

<Accordion>
      <Accordion.Item>
        <Accordion.Header>Table Of Contents</Accordion.Header>
        <Accordion.Body>
         <a href='#wbcsexamupdates'>WBCS 2022 Exam Latest Updates</a> 
          <hr/>
          <a href='#wbcsresult'>WBCS 2022 Result</a>
          <hr/>
          <a href='#wbcsexamdate'>WBCS 2022 Exam Date</a>
          <hr/>
          <a href='#wbcscutoff'>WBCS 2022 Cutoff</a>
          <hr/>
          <a href='#wbcs-apply-online'>WBCS 2022 Apply Online</a>
          <hr/>
          <a href='#wbcs_2022_notifications'>WBCS 2022 Notifications PDF</a>
          <hr/>
          <a href='#wbcs_exam'>What is WBCS Exam?</a>
          <hr/>
          <a href='#wbcs_2022_eligibility'>WBCS 2022 Eligibily Criteria</a>
          <hr/>
          <a href='#wbcs_2022_vacancy'>WBCS 2022 Vacancy</a>
          <hr/>
          <a href='#wbcs_2022_salary'>WBCS 2022 Salary</a>
          <hr/>
          <a href='#wbcs_2022_exam_pattern'>WBCS 2022 Exam Pattern</a>
          <hr/>
          <a href='#wbcs_2022_syllabus'>WBCS 2022 Syllabus</a>
          <hr/>
          <a href='#wbcs_2022_admit_card'>WBCS 2022 Admit Card</a>
          <hr/>
          <a href='#wbcs_2022_answer_key'>WBCS 2022 Answer Key</a>
          {/* <hr/>
          <a href='#wbcs_exam_faq'>WBCS Exam FAQ</a>  */}
          
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>

<h3 id="wbcsexamupdates">WBCS 2022 Exam Latest Updates</h3>
<p>
Candidates are eagerly waiting for the WBCS 2022 Result and cut-off. It is expected that the WBPSC will release the Prelims result in the Month of August 2022. After that, the commission opens the application window for Mains Exam. It is the second step of the written exam. We will update the official link here to check the WBSC Results 2022 Prelims exam.
</p>

<p>

Basically, the WBPSC WBCS 2022 Prelims exam is conducted with an aim of eliminating non-serious or ineligible candidates. Keep in mind that, only those candidates will appear in the Mains exam who will score equal to or more than WBCS Cut off.
</p>

<p>
Earlier, the WBPSC released the WBCS Mains Exam Date for the 2021 cycle. According to that announcement all the candidates who appeared for the WBCS prelims exam held on 22 August 2021 and qualified for it, are eligible to appear for the WBCS Mains exam. It will be held on 20th, 21st, 22nd, and 24th May 2022.
</p>

<h3 id='wbcsresult'>WBCS 2022 Result</h3>

<p>
WBCS 2022 Prelims Result release date is expected in August 2022. After the official release candidates can check the result for the exam on the WBPSC official website. To check the WBCS Result 2022, candidates need to have credentials handy, so you can proceed to check the result easily.
</p>

<h3 id='wbcsexamdate'>WBCS Exam Date 2022</h3>

<p>
WBPSC has announced the WBCS Exam Date 2022 along with the official notification released on the official website which is wbpsc. gov. in. The table below has the entire datasheet for upcoming events under WBPSC WBCS Exam 2022. Applicants are advised to note the released dates to plan their exam preparation accordingly.
</p>

<Table striped bordered hover>
      <thead>
          <th>Events(2022)</th>
          <th>Dates</th>
      </thead>
      <tbody>
        <tr>
          <td>WBCS 2022 Application Form Start Date</td>
          <td>March 03, 2022</td>
        </tr>
        
        <tr>
          <td>Last Date To Apply Online for WBCS 2022</td>
          <td>March 24, 2022</td>
        </tr>
        <tr>
            <td>Last Date to pay the WBCS Application Fee</td>
            <td>March 25, 2022</td>
        </tr>
        <tr>
          <td>WBCS Application Correction window</td>
          <td>April 1 to 7, 2022</td>
        </tr>
        <tr>
          <td>WBCS 2022 Exam Date for Prelims</td>
          <td>June 19, 2022</td>
        </tr>
        <tr>
          <td>WBCS Result 2022 Release Date</td>
          <td>August 2022 (Tentative)</td>
        </tr>
        <tr>
          <td>WBPSC WBCS Exam Date 2022 for Mains</td>
          <td>To Be Notified</td>
        </tr>
        <tr>
          <td>Answer Key For Mains Exam 2022</td>
          <td>To Be Notified</td>
        </tr>
        <tr>
          <td>Mains Exam Result Announcement</td>
          <td>To Be Notified</td>
        </tr>
      </tbody>
    </Table>

<h3 id='wbcscutoff'>WBCS 2022 Cutoff</h3>

<p>
WBPCS releases the WBCS cut-off each year after the examination. The WBCS cutoff is determined based on various parameters such as the difficulty level of the exam, the average performance of the candidates, the number of vacancies, etc. The WBCS Cut off 2022 is yet to be released. Keep in mind that, you can proceed to the next round of exams if you will get shortlisted at the current cut-off. WBCS cut-off category-wise 2019 is stated below
</p>

<p>Check the final WBPSC Cutoff Marks 2019 for all the categories below. </p>

<Table striped bordered hover>
      <thead>
        
          <th>Categories</th>
          <th>Marks</th>
        
      </thead>
      <tbody>
        <tr>
          <td>UR</td>
          <td>1037.8332</td>
        </tr>
        
        <tr>
          <td>BC(A)</td>
          <td>1005.6667</td>
        </tr>
        <tr>
            <td>BC(B)</td>
            <td>983.6666</td>
        </tr>
        <tr>
          <td>SC</td>
          <td>930.3334</td>
        </tr>
        <tr>
          <td>ST</td>
          <td>888.6667</td>
        </tr>
        <tr>
          <td>PH(VH)</td>
          <td>841.0000</td>
        </tr>
        
      </tbody>
    </Table>

<h3>WBCS 2022 Apply Online</h3>

<p>
WBCS 2022 Application Form is the first step to enrolling for the exam. Candidates who are interested in applying for the post mentioned in the notification must visit the website that is wbpsc. gov. in to apply online for WBCS Exam 2022. Applicants must check the steps to apply for the exam and all the relevant information is given below.
</p>

<h4 id='wbcs-apply-online'>How to Apply Online for the WBCS 2022?</h4>

<ul>
    <li>Visit the official website and register yourself using a valid email id and phone number.</li>
    <li>After validation with an OTP, fill out the complete WBCS application form 2022 with the correct information.</li>
    <li>Upload the scanned photograph and signature. Please note that the size should not be more than 100Kb.</li>
    <li>Proceed to make the payment of the WBCS application fee 2022 to complete the application form.</li>
    <li>Save a copy of the completed WBCS application form 2022 for future reference.</li>
</ul>

<h4>WBCS 2022 Application Fees</h4>
<p>
The WBCS application fees are a non-refundable amount for all the categories. However, SC/ST candidates of WB State and Persons with disabilities (40% and above) having a physical disability are not required to pay any WBCS application fee. The fee will be paid only in online mode. Fees paid through other medium will not be accepted.
</p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>Categories</th>
          <th>Fees</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>General/ OBC/ EWS</td>
          <td>₹ 210 + Services Charges/ GST</td>
        </tr>
        
        <tr>
          <td>SC/ST/ PwD (only West Bengal)</td>
          <td>No Fees</td>
        </tr>
        <tr>
            <td>SC/ST/ PwD of other states </td>
            <td>₹ 210 + Services Charges/ GST</td>
        </tr>
      </tbody>
    </Table>

<h3 id='wbcs_2022_notifications'>WBCS Notification 2022 PDF</h3>
<p>
WBPSC Notification 2022 was released on February 24, 2022. PSC West Bengal released the WBCS 2022 notification details as well as the WBCS application dates 2022 on the official website. The notification pdf notified West Bengal Civil Service (Executive) post and others details. The WBCS Vacancy 2022 is yet to be released as per the requirements spread across Group A, Group B, Group C, and Group D posts.
</p>

<p><a href={notifications} download target='_blank'>WBPSC 2022 Notification PDF</a></p>

<h3 id='wbcs_exam'>What is WBCS Exam?</h3>

<p>
The West Bengal State Public Service Commission conducts WBCS examinations to recruit eligible candidates for Grade A, B, C, and D posts. All applications for the exam are to be submitted online at the official portal only. WBPSC released the WBCS 2022 Notification PDF on the official site on 3 March 2022.

</p>

<p>
As per the notification, the exam conducting body will conduct the WBCS 2022 Exam in three stages. First, two stages are Prelims and Mains Exam. Basically, they are written tests. The third stage of the selection process is the interview. Candidates need to qualify each state with good marks to move forward in the selection process. The West Bengal Civil Services Exam 2022 highlights are mentioned below:
</p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>WBPSC 2022 Overview</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Exam Name</td>
          <td>West Bengal Civil Services</td>
        </tr>
        
        <tr>
          <td>Conducting Body</td>
          <td>West Bengal Public Service Commission</td>
        </tr>
        <tr>
            <td>Mode of the examination</td>
            <td>Offline</td>
        </tr>
        <tr>
          <td>Exam Stages</td>
          <td>Three stages :<br/>
WBCS Prelims Exam<br/>
WBCS Mains Exam<br/>
WBCS Personality Test</td>
        </tr>
        <tr>
          <td>Exam Frequency</td>
          <td>once a year</td>
        </tr>
        <tr>
          <td>Eligibility Criteria</td>
          <td>graduate degree required</td>
        </tr>
        <tr>
          <td>Language of the exam</td>
          <td>English & Bengali</td>
        </tr>
        <tr>
          <td>
          Official Website
          </td>
          <td><a href='https://wbpsc.gov.in' target='_blank'>wbpsc.gov.in</a></td>
        </tr>
      </tbody>
    </Table>

<h3 id='wbcs_2022_eligibility'>WBCS Eligibility Criteria 2022</h3>

<p>
WBCS eligibility Criteria 2022 is the minimum requirement to be completed by the applicant for the West Bengal Civil Service Exam. Required criteria were announced in the official notification. Note that ineligibility found in later stages will lead to your application cancellation.
</p>
<p>
It is advisable that check your eligibility before submitting the WBCS Application Form. As per the eligibility criteria for WBCS 2022, candidates should fulfill the age limit, education criteria, and nationality criteria as mentioned below.
</p>

<h5>Nationality</h5>
<p>As per the WBCS 2022 eligibility criteria, the candidate must be an Indian Citizen.</p>

<h5>WBCS Age Limit</h5>
<p>
WBPSC has notified the category-wise WBCS age limit for the 2022 exam is mentioned below. Keep in mind that, as a general candidate you should not be younger than 21 years and older than 36 years. The WBCS age limit for general and others will be calculated as on January 01, 2022. Also, note that applicants who belong to another state will be treated as General applicants.
</p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>Groups</th>
          <th>WBCS Age Limit 2022</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>for services and posts included in Group A & C</td>
          <td>	
21- 36 years</td>
        </tr>
        
        <tr>
          <td>West Bengal Police Service only (i.e. Group ‘B’ Service)</td>
          <td>20- 36 years</td>
        </tr>
        <tr>
            <td>for services and posts included in Group ‘D’</td>
            <td>21- 39 years</td>
        </tr>
      </tbody>
    </Table>

    <h5>Age Relaxation</h5>
    <p>There are some relaxations in the WBCS age limit for reserved category candidates. Check the details below.</p>

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Category</th>
          <th>WBCS age relaxation</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>WBCS age relaxation for SC/ST/BC of West Bengal</td>
          <td>5 years</td>
        </tr>     
        <tr>
          <td>WBCS age relaxation for SC/ST of Other States</td>
          <td>5 years</td>
        </tr>
        <tr>
            <td>WBCS age relaxation for OBC (Non-Creamy Layer)</td>
            <td>3 years</td>
        </tr>
        <tr>
          <td>Persons with Disabilities (PWD)</td>
          <td>upto 45 years</td>
        </tr>
      </tbody>
    </Table>

    <h5>WBCS Educational Qualification</h5>

    <p>WBCS 2022 candidates should have a Bachelor's degree from a UGC-recognized university. Also, having proficiency in the Bengali Language will be an advantage. However, this is not mandatory for candidates who have a Nepali mother tongue.</p>

<h3 id='wbcs_2022_vacancy'>WBCS 2022 Vacancy</h3>

<p>
WBPSC will announce the WBCS vacancy 2022 in due time. West Bengal Public Service Commission conducts the West Bengal Civil Services exam. The commission will announce after the requirement given by the various departments of the state government.
</p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>Post</th>
          <th>Total Vacancies</th>
        </tr>
      </thead>
      <tr>
        <td>Group A</td>
      </tr>
      <tbody>
          <tr>
          <td>West Bengal Civil Service (Executive)</td>
          <td>To be announced</td>
        </tr>     
        <tr>
          <td>Assistant Commissioner of Revenue</td>
          <td>To be announced</td>
        </tr>
        <tr>
            <td>West Bengal Co-operative Service</td>
            <td>To be announced</td>
        </tr>
        <tr>
            <td>West Bengal Labour Service</td>
            <td>To be announced</td>
        </tr>
        <tr>
            <td>West Bengal Food and Supplies Service</td>
            <td>To be announced</td>
        </tr>
        <tr>
            <td>West Bengal Employment Service</td>
            <td>To be announced</td>
        </tr>
        <tr>
          <td>Group B</td>
        </tr>
        <tr>
        <td>West Bengal Police Service(Group B)</td>
        <td>To be announced</td>
        </tr>
        <tr>
        <td>Group C</td>
        <td>To be announced</td>
        </tr>
        <tr>
        <td>Group D</td>
        <td>To be announced</td>
        </tr>
      </tbody>
    </Table>

    <h3 id='wbcs_2022_salary'>WBCS 2022 Salary</h3>
    <p>
    The WBPSC has notified the WBCS Officer Salary according to the pay scale mentioned in the notification 2022. The below-mentioned table has the complete information about the WBCS Officer Salary structure. This includes the various components of salary like DA, HRA, and others as admissible. The WBPSC salary 2022 is mentioned below post-wise and differentiated across Group services.
    </p>

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Post</th>
          <th>Pay Scale</th>
        </tr>
      </thead>
      <tr>
        <td>Group A</td>
      </tr>
      <tbody>
        <tr>
          <td>West Bengal Civil Service (Executive)</td>
          <td>Pay Level:16 (56,100 - 1,44,300) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>     
        <tr>
          <td>Assistant Commissioner of Revenue</td>
          <td>Pay Level:16 (56,100 - 1,44,300) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
            <td>West Bengal Co-operative Service</td>
            <td>Pay Level:16 (56,100 - 1,44,300) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>West Bengal Labour Service</td>
          <td>Pay Level:16 (56,100 - 1,44,300) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>West Bengal Food and Supplies Service</td>
          <td>Pay Level:16 (56,100 - 1,44,300) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>West Bengal Employment Service</td>
          <td>Pay Level:16 (56,100 - 1,44,300) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
      </tbody>
      <tr>
        <td>Group B</td>
      </tr>
      <tbody>
        <tr>
          <td>
          West Bengal Police Service
          </td>
          <td>Pay Level:16 (56,100 - 1,44,300) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
      </tbody>
      <tr>
        <td>Group C</td>
      </tr>
      <tbody>
        <tr>
          <td>
          Superintendent, District Correctional Home / Deputy Superintendent, Central Correctional Home
          </td>
          <td>Pay Level:15 (42,600 - 1,09,800) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Joint Block Development Officer</td>
          <td>Pay Level:14(39,900 - 1,02,800) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Deputy Assistant Director of Consumer Affairs and Fair Business Practices</td>
          <td>Pay Level:14 (39,900 - 1,02,800) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>West Bengal Junior Social Welfare Service</td>
          <td>Pay Level:14 (39,900 - 1,02,800) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>West Bengal Subordinate Land Revenue Service, Grade-I</td>
          <td>Pay Level:14 (39,900 - 1,02,800) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Assistant Commercial Tax Officer</td>
          <td>Pay Level:14 (39,900 - 1,02,800) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Joint Registrar (West Bengal State Consumer Disputes Redressal Commission under the Consumer AffairsDepartment, Government of West Bengal)</td>
          <td>Pay Level:14 (39,900 - 1,02,800) (besides D.A., M.A. and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Assistant Canal Revenue Officer (Irrigation)</td>
          <td>Pay Level: 12 (35,800 – 92,100) (besides D.A., M.A., and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Chief Controller of Correctional Services</td>
          <td>Pay Level: 12 (35,800 – 92,100) (besides D.A., M.A., and H.R.A. admissible as per rules)</td>
        </tr>
      </tbody>
      <tr>
        <td>Group D</td>
      </tr>
      <tbody>
        <tr>
          <td>Inspector of Co-operative Societies</td>
          <td>Pay Level: 10 (32,100 - 82,900) (besides D.A., M.A., and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Panchayat Development Officer under the Panchayat and Rural Development
Department</td>
          <td>Pay Level: 10 (32,100 - 82,900) (besides D.A., M.A., and H.R.A. admissible as per rules)</td>
        </tr>
        <tr>
          <td>Rehabilitation Officer under the Refugee Relief and Rehabilitation Department</td>
          <td>Pay Level: 10 (32,100 - 82,900) (besides D.A., M.A., and H.R.A. admissible as per rules)</td>
        </tr>
      </tbody>
    </Table>

<h3 id='wbcs_2022_exam_pattern'>WBCS 2022 Exam Pattern</h3>
<p>
WBPSC has notified the WBCS 2022 Exam Pattern from the official notification. As mentioned in the notification, there will be 3 stages for the exam. All of them are mentioned below which is mentioned below:
</p>
<ul>
  <li>WBCS Prelims Exam</li>
  <li>WBCS Mains Exam</li>
  <li>WBCS Personality Test (Interview)</li>
</ul>

<p>
WBCS Prelims 2022 exam is a like screening test to shortlist candidates for the WBCS Mains Exam. It is the first written test and all the candidates have to appear in this exam. The Mains and Interview scores are counted for the final selection list. Knowing the WBCS 2022 Exam Pattern for Prelims and Mains test will help candidates to prepare according. Details are mentioned below.
</p>

<h5>WBCS Prelims Exam Pattern 2022</h5>
<ul>
  <li>The WBCS Prelims exam has 1 Paper.</li>
  <li>All the questions will be Multiple Choice Questions.</li>
  <li>The Prelims paper consists of General Studies and Aptitude questions of 200 marks to be solved in 2.5 hours duration.</li>
  <li>The marks obtained from this exam will not be added to the final result. It is only a screening test to admit students to the main examination</li>
</ul>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>Topics</th>
          <th>Marks</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>English Composition</td>
          <td>25 Marks</td>
        </tr>
        
        <tr>
          <td>General Science</td>
          <td>25 Marks</td>
        </tr>
        <tr>
          <td>Current events of National & International Importance</td>
          <td>25 Marks</td>
        </tr>
        <tr>
          <td>History of India</td>
          <td>25 Marks</td>
        </tr>
        <tr>
          <td>Geography of India (special reference to West Bengal)</td>
          <td>25 Marks</td>
        </tr>
        <tr>
          <td>Indian Polity and Economy</td>
          <td>25 Marks</td>
        </tr>
        <tr>
          <td>Indian National Movement</td>
          <td>25 Marks</td>
        </tr>
        <tr>
          <td>General Mental Ability</td>
          <td>25 Marks</td>
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Total</td>
          <td>200 Marks</td>
        </tr>
      </tbody>
    </Table>

    <h5>WBPSC WBCS Mains Exam Pattern 2022</h5>

    <p>
    The WBPSC Mains Exam pattern consists of 6 compulsory papers consisting of 200 marks each (objective and written) and 2 optional papers (Only for candidates applying for group A and/or B). The list of WBCS Mains Exam Compulsory Papers is mentioned below. 
    </p>
    <p>
    Note- Group ‘A’ & ‘B’: All 6 compulsory papers and one optional subject consisting of two papers. Group ‘C’ & ‘D’: All 6 compulsory papers.
    </p>
    
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Paper</th>
          <th>Subject</th>
          <th>Type</th>
          <th>Duration</th>
        </tr>     
      </thead>
      <tbody>
      <tr>
        <td>Paper I</td>
        <td>Bengali/ Hindi/ Urdu/ Nepali/ Santali</td>
        <td>Essay Type</td>
        <td>3 hours</td>
      </tr>
      <tr>
        <td>Paper-II</td>
        <td>English</td>
        <td>Essay Type</td>
        <td>3 hours</td>
      </tr>
      <tr>
        <td>Paper III</td>
        <td>General Studies I</td>
        <td>Objective</td>
        <td>3 hours</td>
      </tr>
      <tr>
        <td>Paper IV</td>
        <td>General Studies II</td>
        <td>Objective</td>
        <td>3 hours</td>
      </tr>
      <tr>
        <td>Paper V</td>
        <td>	
Indian Constitution & Indian Economy</td>
        <td>Objective</td>
        <td>3 hours</td>
      </tr>
      <tr>
        <td>Paper VI</td>
        <td>Arithmetic & Test of Reasoning</td>
        <td>Objective</td>
        <td>3 hours</td>
      </tr>
      </tbody>
    </Table>
    

    <h5>WBPSC Optional Papers</h5>
    
    <ul>
      <li>Paper I- 200 marks</li>
      <li>Paper II- 200 marks</li>
    </ul>

    <p>One subjects have to be selected from a list of Optional subjects. Both the papers will be for 3 hours and essay type.</p>
   
    <Table striped bordered hover responsive>
      
      <tbody>
      <tr>
        <td>Hindi</td>
        <td>Santali</td>
        <td>Statistics</td>
        <td>Management</td>
        <td>Law</td>
      </tr>
      <tr>
        <td>Commerce and Accountancy</td>
        <td>Comparative Literature</td>
        <td>Computer Science</td>
        <td>Civil Engineering</td>
        <td>Electrical Engineering</td>
      </tr>
      <tr>
        <td>Sanskrit</td>
        <td>Agriculture</td>
        <td>Economics</td>
        <td>Philosophy</td>
        <td>Physiology</td>
      </tr>
      <tr>
        <td>English</td>
        <td>Animal Husbandry and Veterinary Science</td>
        <td>Mechanical Engineering</td>
        <td>Political Science</td>
        <td>Psychology</td>
      </tr>
      <tr>
        <td>Pali</td>
        <td>Botany</td>
        <td>Geography</td>
        <td>Zoology</td>
        <td>French</td>
      </tr>
      <tr>
        <td>Persian</td>
        <td>Chemistry</td>
        <td>Geology</td>
        <td>History</td>
        <td>Mathematics</td>
      </tr>
      <tr>
        <td>Sociology</td>
        <td>Anthropology</td>
        <td>Medical Science</td>
        <td>Physics</td>
        
      </tr>
      
      </tbody>
    </Table>
    
    <h5>WBCS Personality Test 2022 (Interview)</h5>
    <p>
    All those candidates who clear the WBCS Mains exam are invited for a personality test i.e. the interview. This is the final stage of the selection process. It means there is no exam and no other tests. The final list of recommended aspirants will be based on the total marks obtained in the Main exam and the personality test.
    </p>

    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Group of Service</th>
          <th>Marks of Interview</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>Group 'A' and 'B'</td>
            <td>200 Marks</td>
          </tr>
          <tr>
            <td>Group 'C'</td>
            <td>150 Marks</td>
          </tr>
          <tr>
            <td>Group 'D'</td>
            <td>100 Marks</td>
          </tr>
        </tbody>
    </Table>

    <h3 id='wbcs_2022_syllabus'>WBCS 2022 Syllabus</h3>
    <p>
    WBCS Syllabus 2022 PDF is mandatory to analyze if you are going to appear in the exam. Candidates should have information about the important topics in the Prelims and Mains Exam. It is an important step for preparation. The WBCS Syllabus 2022 pdf can be downloaded from the official website or by visiting the syllabus tab page. Take a look at the Prelims and Mains syllabus exam below:
    </p>

    <h5>WBCS Prelims Syllabus 2022</h5>

    <ol>
      <li>English Composition, Synonyms, Antonyms, Idioms and Phrases, Vocabulary test, Phrasal Verbs, Same words bearing more than one meaning and use of appropriate and qualifying words.</li>
      <li>General Science</li>
      <li>History of India</li>
      <li>Geography of India</li>
      <li>Indian Polity and Economy</li>
      <li>General Mental Ability</li>
    </ol>

    <h5>WBCS Main Exam Syllabus 2022</h5>

    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Paper</th>
          <th>Subject</th>
          <th>Syllabus</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Paper I</td>
          <td>Bengali/ Hindi/ Urdu/ Nepali/ Santali</td>
          <td>Letter writing / Report writing, Precis Writing, Composition, and Translation from English to Bengali/Hindi/Urdu/Nepali/Santali</td>
        </tr>
        <tr>
          <td>Paper-II</td>
          <td>English</td>
          <td>Letter writing / Report writing, Precis Writing, Composition, and Translation from Bengali/Hindi/Urdu/Nepali/Santali to English</td>
        </tr>
        <tr>
          <td>Paper III</td>
          <td>General Studies I</td>
          <td>
            <ol>
              <li>Indian History(special emphasis on National Movement)</li>
              <li>Geography of India(special reference to West Bengal)</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>Paper IV</td>
          <td>General Studies II</td>
          <td>
            <ol>
              <li>Science and Scientific & Technological advancement</li>
              <li>Environment (Biodiversity and Coastal Regulation Zone, Global Warming, Industrial and Environmental Pollution, Ozone Layer and related issues.)</li>
              <li>General Knowledge and Current Affairs</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>Paper V</td>
          <td>Indian Constitution & Indian Economy</td>
          <td>
            <ol>
              <li>The Constitution of India</li>
              <li>Central State relation, devolution of central funds to state</li>
              <li>Indian Economy (Planning and objectives of five years plan, Monetary policy and Functions of Reserve Bank of India, Central and State Finance Commission, Fiscal Policy of Government of India.)</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td>Paper VI</td>
          <td>Arithmetic & Test of Reasoning</td>
          <td>
            <ol>
              <li>Logical Deduction</li>
              <li>The forcefulness of the Arguments</li>
              <li>Implication of sentences</li>
              <li>Inferring from diagrams</li>
              <li>Series, Letter series</li>
              <li>Number Series, Analogy tests, Symbol Interpretation, Mathematical puzzles, Odd man out, Perception test, Non-verbal reasoning & Selecting the correct sequence.</li>
            </ol>
          </td>
        </tr>
      </tbody>
    </Table>

<h3 id='wbcs_2022_admit_card'>WBCS 2022 Admit Card</h3>

<p>
The WBCS 2022 Admit Card is released by the commission. It is an essential document to be carried at the exam center along with any government ID proof. Check the direct link to download the WBCS admit card. Candidates must follow all the guidelines given on the admit card.
</p>

<p style={{fontWeight:'bold'}}>Download WBCS Admit Card For Mains 2022 (To be notified)</p>

<h3 id='wbcs_2022_answer_key'>WBPSC 2022 Answer Key</h3>
<p>
WBPSC releases the WBCS Answer Key 2022 on the official website after the exam completion. Candidates can download the official provisional answer key for WBCS 2022 from the official website soon. Candidates usually wait for the answer key after the exam to evaluate their performance and predict the score.
</p>
       
        
        </div>
        </Container>
    )
}

export default WbcsOverview;