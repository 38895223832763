import { useState, Fragment } from "react";
import { tutionFeesPaymentHandler } from "../payment-form/tution-fees/tution-fees.payment";
import {
  Box,
  TextField,
  Grid,
  Container,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

const TutionFeesNew = () => {
  const [showSpinner, setSpinner] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSpinner(true);
    const data = new FormData(event.currentTarget);
    const submitData = {
      name: data.get("Name"),
      email: data.get("email"),
      mobile: data.get("mobile"),
      amount: data.get("fees"),
      address: data.get("address"),
    };

    const response = await tutionFeesPaymentHandler(submitData);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Pay Tution Fees Online | Ahguide Tution Payment </title>
        <meta
          name="description"
          content="Pay online Ahguide tution fees payment by credit card,debit card, net banking,upi and more payment option. "
        />
        <link rel="canonical" href="https://www.ahguide.in/tution-fees" />
      </Helmet>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            marginTop: 5,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="div" variant="h3">
            Tution Fees
          </Typography>
          <Typography
            sx={{ marginTop: "10px" }}
            component="div"
            variant="body1"
          >
            Please enter your details and click on Pay Now button.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  autoComplete="given-name"
                  name="Name"
                  required
                  fullWidth
                  id="Name"
                  label="Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="tel"
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="mobile-no"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  autoComplete="Address"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  fullWidth
                  id="fees"
                  label="Fees"
                  name="fees"
                  autoComplete="fees-amount"
                />
              </Grid>
            </Grid>
            {showSpinner ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="warning"
                sx={{ mt: 3, mb: 2 }}
              >
                Pay Now
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default TutionFeesNew;
