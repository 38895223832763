import { Card,Row,Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import './mock.style.css';
import { Helmet } from "react-helmet-async";
import { Fragment } from "react";

const Mock = () =>{
    
    const mockData = [{
        title : 'WBCS Mock Test',
        url : 'wbcs-mock-test'
    },
    {
        title : 'IAS Mock Test',
        url : 'ias-mock-test'
    }
]


    return (

        <Fragment>

            <Helmet>
                <title>Mock Test Free | Mock Test Series | Mock Test Online</title>
                <meta name="description" content="Start with free mock test for WBCS and IAS Prelims exam preparation. Attempt WBCS and IAS online test series to clear the exam." />        
                <link rel="canonical" href="https://www.ahguide.in/mock-test" />
            </Helmet>


        
        <div className="mock-container">
            <h2>Select your exam category</h2>
            <p>Click to view all available test series in an exam</p>
            <Row md={6} xs={1} className="g-4">
            {
                mockData.map((data)=>{
                    const {title,url} = data;
                    return (
                        
                <Col key={title}>
                <Card className='mock-cards'>
                    <Card.Body>
                   <Link to={url}><Card.Title>{title}</Card.Title></Link>
                    </Card.Body>
                </Card>
                </Col>
            

                    )
                })
            }
          </Row>  

          
        </div>

        </Fragment>
    )
}

export default Mock;