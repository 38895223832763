import { Typography,Box,Container,Stack } from "@mui/material";


const CoursesBlog = ({courseData})=>{

    const {title,description,date} = courseData;

    return (
        <Container>
        <Stack spacing={2}  sx={{marginTop: '20px'}}>
            <Typography variant="h4" component="div">
                {title}
            </Typography>
            <Typography variant="body1" component="div">
                {date}

            </Typography>
            <Typography variant="body1" component="div">
              {description}
            </Typography>


        </Stack>
        </Container>
    )

}

export default CoursesBlog;