import './checkout.style.css';

import logo from '../../assets/img/ahguide-logo.jpg';

import { useDispatch,useSelector } from 'react-redux';

import { removeItemFromCart,addItemToCart,clearItemFromCart } from '../../store/cart/cart.action';

import { selectCartItems } from '../../store/cart/cart.selector';

import {selectCurrentUser} from '../../store/user/user.selector';

import { setCartItemsToDatabase } from "../../utils/firebase/firebase.utils";

import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';




const CheckoutItem = ({product})=>{

    const {quantity,title,price} = product;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const cartItems = useSelector(selectCartItems);

    const currentUser = useSelector(selectCurrentUser);

    useEffect(()=>{
        if(!currentUser) return;
        setCartItemsToDatabase(currentUser.id,cartItems);

    },[currentUser,cartItems])


    const reduceItemHandler = ()=>
        dispatch(removeItemFromCart(cartItems,product));
    

    const increaseItemHandler = ()=>
        dispatch(addItemToCart(cartItems,product));

    const removeItemHandler= () => {
        if(!currentUser){
            dispatch(clearItemFromCart(cartItems,product));
        }
        else {
            setCartItemsToDatabase(currentUser.id,[]);
            dispatch(clearItemFromCart(cartItems,product));
        }
        
        
       
                                   

    }
         
    return (
        <div>
            <div style={{display:'flex',justifyContent:'space-evenly',columnGap:'10px'}}>
                <img src={logo} alt='ahguide' style={{width:'60px'}}/>
                <div><span style={{cursor:'pointer'}} onClick={reduceItemHandler}>&#10094;</span>{quantity}<span style={{cursor:'pointer'}} onClick={increaseItemHandler}>&#10095;</span></div>
                <div>{title}</div>
                <div>{price}</div>
                <div onClick={removeItemHandler} style={{cursor:'pointer'}} >&#10005;</div>
            </div>

                
             
        </div>
    )
}

export default CheckoutItem;