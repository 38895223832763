import { ReactComponent as ShoppingIcon } from '../../assets/img/shopping-bag.svg';

import './cart.style.css';

import { useDispatch,useSelector } from 'react-redux';

import { setIsCartOpen } from '../../store/cart/cart.action';

import { selectIsCartOpen,selectCartCount } from '../../store/cart/cart.selector';

const CartIcon = ()=>{

  const dispatch = useDispatch();

  const isCartOpen = useSelector(selectIsCartOpen);

  const cartCount = useSelector(selectCartCount);

  const toggleIsCartOpen =()=>dispatch(setIsCartOpen(!isCartOpen));
    return (
        <div onClick={toggleIsCartOpen} className='cart-icon-container'>
        <ShoppingIcon className='shopping-icon' />
      <span className='item-count'>{cartCount}</span>
    </div>
  );
    
}

export default CartIcon;