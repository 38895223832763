import { Typography,Box } from "@mui/material";
import { useState,useEffect } from "react";
import CoursesBlog from "../../../../Blog/courses-blog.component";
import { getCoursesForthCard } from "../../../../../utils/firebase/firebase.utils";
import { Helmet } from "react-helmet-async";

const CoursesForthCard = ()=>{

    const [courseData,setCourseData] = useState([]);

    useEffect(()=>{
        const getData = async()=>{
            const arrayData = await getCoursesForthCard();
            const{data} = arrayData;
            setCourseData(data.reverse());
        
        }

        getData();
        
        
    },[])




    return(
        <>
        <Helmet>
                <title> Test Series for Prelims & Mains | Ahguide  </title>
                <meta name="description" content="Boost your WBCS exam preparation and score better with Our WBCS Test Series for WBCS Prelims and Mains." />        
                <link rel="canonical"  href="https://www.ahguide.in/test-series" />
            </Helmet>
        <Box>
            <Typography sx={{textAlign:"center",marginTop:'20px'}} variant="h3" component="div">
                Test Series for Prelims & Mains
            </Typography>
            {
                courseData.map((data)=>{
                    return  <CoursesBlog key={data.title} courseData= {data}  />
                })
            }

        </Box>
        </>
    )

}

export default CoursesForthCard;