import { useEffect } from "react";
import { getOrderDetails } from "../../utils/firebase/firebase.utils";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {clearAllCartItems} from '../../store/cart/cart.action'; 



const PaymentSuccess = ()=>{

    const randomNumber = Math.floor(Math.random() * 800000) + 710000;
    
    const dispatch = useDispatch();

    const currentUser = useSelector(selectCurrentUser);

    const [orderNumber,setOrderNumber] = useState(null);


    

    useEffect(()=>{
        const orderDetails = async()=>{

            dispatch(clearAllCartItems());


            if(currentUser) {
                var email = currentUser.email;
                const data = await getOrderDetails(email);
                const {orderid} = data;

                setOrderNumber(orderid)

            }         
    
        }
        orderDetails();

    },[])

    

    
    return (
        
            <div>
                
                <h1>Order Received</h1>
                {(orderNumber===null)? <p>Your order number is: {randomNumber} </p>   
                            :  <p>Your order number is: {orderNumber}  </p>

                            
            }
                
                
                </div>
                        
            
        
    )
}

export default PaymentSuccess;