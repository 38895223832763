import { Tabs,Tab, Button } from "react-bootstrap";
import Mock from "../../routes/Mock/mock.component";
import Login from "../Login/login.components";
import Signup from "../Signup/signup.components";

import './wbcs-study-material.style.css';
import WbcsAnswerKey from "./Wbcs/Answer/wbcs-answer.component";
import WbcsQuestion from "./Wbcs/Question/wbcs-question.component";
import WbcsOverview from "./Wbcs/wbcs-overview.component";

const WbcsStudyMaterial = ()=>{
    return (
<div>
    <div className="wbcs-title">
        <h2>WBCS 2022: Prelims Exam Date (Out), Notification, Updates</h2>
        <p>WBCS Exam 2022 exam date is out for Prelims and Mains. Check details such as exam date, admit card, vacancy, fees, eligibility, selection process, exam pattern, syllabus, result and news.</p>
        <div className="wbcs-study-button"><Button variant="warning" href='/mock-test'>Signup for Free Mock Test</Button></div>
    </div>

<Tabs defaultActiveKey="overview" id="uncontrolled-tab-example" className="mb-3 wbcs-study-tabs">
  <Tab eventKey="overview" title="Overview">
    <WbcsOverview />
  </Tab>
  <Tab eventKey="answer-key" title="Answer Key">
    <WbcsAnswerKey />
  </Tab>
  <Tab eventKey="question-paper" title="Question Paper">
    <WbcsQuestion />
  </Tab>
</Tabs>
        </div>
    )
}

export default WbcsStudyMaterial;