import { Container, Typography, Stack, Box } from "@mui/material";
import Footer from "../../../components/Footer/footer.component";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title> Privacy Policy | Ahguide </title>
        <meta
          name="description"
          content="At Ahguide, accessible from https://www.ahguide.in, one of our main
            priorities is the privacy of our visitors."
        />
        <link rel="canonical" href="https://www.ahguide.in/privacy-policy" />
      </Helmet>
      <Container sx={{ marginBottom: "10px" }}>
        <Stack spacing={2}>
          <Typography variant="h3" component="h2">
            Privacy Policy
          </Typography>
          <Typography variant="body1" component="div">
            At Ahguide, accessible from https://www.ahguide.in, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by Ahguide and how we use it.
          </Typography>
          <Typography variant="body1" component="div">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </Typography>
          <Typography variant="body1" component="div">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in Ahguide. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </Typography>
          <Typography variant="h4" component="div">
            Consent
          </Typography>
          <Typography variant="body1" component="div">
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </Typography>
          <Typography variant="h4" component="div">
            Information we collect
          </Typography>
          <Typography variant="body1" component="div">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </Typography>
          <Typography variant="body1" component="div">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </Typography>
          <Typography variant="body1" component="div">
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </Typography>
          <Typography variant="h4" component="div">
            How we use your information
          </Typography>
          <Typography>
            We use the information we collect in various ways, including to:
          </Typography>
          <Box>
            <ul>
              <li>
                <Typography variant="body1" component="div">
                  Provide, operate, and maintain our website
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="div">
                  Improve, personalize, and expand our website
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="div">
                  Understand and analyze how you use our website
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="div">
                  Develop new products, services, features, and functionality
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="div">
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="div">
                  Send you emails
                </Typography>
              </li>
              <li>
                <Typography variant="body1" component="div">
                  Find and prevent fraud
                </Typography>
              </li>
            </ul>
          </Box>
          <Typography variant="h4" component="div">
            Log Files
          </Typography>
          <Typography variant="body1" component="div">
            Ahguide follows a standard procedure of using log files. These files
            log visitors when they visit websites. All hosting companies do this
            and a part of hosting services' analytics. The information collected
            by log files include internet protocol (IP) addresses, browser type,
            Internet Service Provider (ISP), date and time stamp, referring/exit
            pages, and possibly the number of clicks. These are not linked to
            any information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the site,
            tracking users' movement on the website, and gathering demographic
            information.
          </Typography>
          <Typography variant="h4" component="div">
            Cookies and Web Beacons
          </Typography>
          <Typography variant="body1" component="div">
            Like any other website, Ahguide uses 'cookies'. These cookies are
            used to store information including visitors' preferences, and the
            pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </Typography>
          <Typography variant="h4" component="div">
            Advertising Partners Privacy Policies
          </Typography>
          <Typography variant="body1" component="div">
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of Ahguide.
          </Typography>
          <Typography variant="body1" component="div">
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on Ahguide, which
            are sent directly to users' browser. They automatically receive your
            IP address when this occurs. These technologies are used to measure
            the effectiveness of their advertising campaigns and/or to
            personalize the advertising content that you see on websites that
            you visit.
          </Typography>
          <Typography variant="body1" component="div">
            Note that Ahguide has no access to or control over these cookies
            that are used by third-party advertisers.
          </Typography>
          <Typography variant="h4" component="div">
            Third Party Privacy Policies
          </Typography>
          <Typography variant="body1" component="div">
            Ahguide's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.
          </Typography>
          <Typography variant="body1" component="div">
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </Typography>
          <Typography variant="h4" component="div">
            GDPR Data Protection Rights
          </Typography>
          <Typography variant="body1" component="div">
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following: The
            right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service. The
            right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete. The right to erasure – You have the right to request
            that we erase your personal data, under certain conditions. The
            right to restrict processing – You have the right to request that we
            restrict the processing of your personal data, under certain
            conditions. The right to object to processing – You have the right
            to object to our processing of your personal data, under certain
            conditions. The right to data portability – You have the right to
            request that we transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </Typography>
          <Typography variant="h4" component="div">
            Children's Information
          </Typography>
          <Typography variant="body1" component="div">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
            Ahguide does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </Typography>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
