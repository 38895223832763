import { Typography, Container, Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Footer from "../../../components/Footer/footer.component";
import "./about-us.style.css";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title> About Us | Who We Are | Ahguide </title>
        <meta
          name="description"
          content="A H GUIDE is a Coaching Institute fully dedicated to West Bengal Civil Service only. It has sole responsibility for all the aspirants admitted here to prepare properly themselves."
        />
        <link rel="canonical" href="https://www.ahguide.in/about-us" />
      </Helmet>
      <div className="container">
        <Stack spacing={2}>
          <Typography variant="h3" component="h2">
            About Us
          </Typography>

          <Typography variant="body1" component="div">
            A H GUIDE is a Coaching Institute fully dedicated to West Bengal
            Civil Service only. It has sole responsibility for all the aspirants
            admitted here to prepare properly themselves.
          </Typography>

          <Typography variant="body1" component="div">
            It has some unique features which include some new techniques,
            concepts etc and provide effective and to the point study materials.
            Many disciplines and self-duties are to be followed by the students
            for overall development of their preparation.
          </Typography>
        </Stack>
      </div>
    </>
  );
};

export default AboutUs;
