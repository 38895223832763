import {Button,Spinner} from 'react-bootstrap';


const GrowSpinner = ()=>{
    return (
    <Button variant="warning" disabled>
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
    />
    Loading...
  </Button>
    )
}

export default GrowSpinner;
