import { Fragment,useState,useEffect } from "react";
import { Col,Card,Row,Button,Badge} from "react-bootstrap";
import { Container,Box,Typography } from "@mui/material";
import './course.style.css';
import { getHomeCoursesData,getHomeCourseTitle,getHomeCoursesDescription } from "../../../utils/firebase/firebase.utils";

const localcourseData = [
    {
        title : 'Regular Classroom Courses',
        text : 'Join Classroom Course for WBCS Basic Courses,Prelims,Mains and Personality Test.',
        border : 'light',
        bg: "info",
        url : '/regular-classroom-course',
        badge: ''
    },
    {
        title : 'Weekend Batches for WBCS',
        text : 'Weekend batches are specially for working professionals. Owing to the nature of their jobs, many professionals can’t join the regular batches in our institute. ',
        border : 'warning',
        bg: "success",
        url : '/wbcs-weekend-batches',
        badge: ''
    },
    {
        title : 'WBCS Correspondence Courses',
        text : 'For the aspirants of distant areas or working professionals who are unable to make use of classroom guidance program, Correspondence Course is the first choice. ',
        border : 'light',
        bg: "warning",
        url : '/wbcs-correspondence-courses',
        badge: ''
    },
    {
        title : 'Test Series for Prelims & Mains',
        text : 'Boost your WBCS exam preparation and score better with Our WBCS Test Series for WBCS Prelims and Mains.',
        border : 'warning',
        bg: "danger",
        url : '/test-series',
        badge: ''
    },
    {
        title : 'Online WBCS Coaching',
        text : 'Our Live Classes is dedicated platform that offers Online WBCS Coaching Classes for entire WBCS examinations. ',
        border : 'light',
        bg: "warning",
        url : '/online-wbcs-coaching',
        badge: ''

    },
    {
        title : 'WBCS Consultant Guidance',
        text : 'Confused about WBCS Preparation? Request counselling Now',
        border : 'warning',
        bg: "info",
        url : '/wbcs-consultant-guidance',
        badge: 'NEW'
    }
]

const Course = ()=>{

    const [courseData,SetCourseData] = useState(localcourseData);

    const [courseTitle,SetCourseTitle] = useState();

    const [courseDescription,SetCourseDescription] = useState();

    

    useEffect(()=>{
        const getCourseData = async()=>{
            const getDataFromDatabase = await getHomeCoursesData('courses');
            const getCourseTitle = await getHomeCourseTitle();
            const getCourseDescription = await getHomeCoursesDescription();
            SetCourseTitle(getCourseTitle);
            SetCourseData(getDataFromDatabase);
            SetCourseDescription(getCourseDescription);


        }

        getCourseData();
    },[])

    
    return (
        <Fragment>
            <Container maxWidth="xl">
            <Box style={{textAlign:'center',marginBottom:'10px'}}>
                <Typography variant="h3" component="div">
                    {courseTitle}
                </Typography>
                <Typography variant="body1" component="div">
                      {courseDescription}
                </Typography>

            </Box>

   
    
    <Row xs={1} md={3} className="g-4">    
  {
      courseData.map((course)=>{
          const {title,text,border,bg,url,badge} = course;
          return (
            <Col key={title}>
            <Card border={border} bg={bg} className='course-card'>
            <a href={url}><Card.Body>
                <Card.Title>{title}{' '}<Badge pill bg="danger">{badge}</Badge></Card.Title>
                <Card.Text>
                  {text}
                </Card.Text>
              </Card.Body></a>
              <Button href={url} variant="outline-light" style={{color:'black'}}>Know More</Button>
            </Card>
          </Col>

          )
      })
  }
    
  
</Row>

            
        </Container>
        </Fragment>

    )

}

export default Course;