import CheckoutItem from "../../components/Checkout/checkout.component";

import { useSelector } from 'react-redux';

import { useNavigate } from "react-router-dom";

import { selectCartItems,selectCartTotal } from '../../store/cart/cart.selector';
import { selectCurrentUser } from "../../store/user/user.selector";
import './checkout.style.css';

import { Button } from "react-bootstrap";

import Footer from "../../components/Footer/footer.component";
import { Fragment } from "react";

import GrowSpinner from "../../components/Spinner/grow-spinner/grow-spinner.component";
import { useState } from "react";

import { Helmet } from "react-helmet-async";

const Checkout = ()=>{

    const [showSpinner,setShowSpinner] = useState(false);

    const cartItems = useSelector(selectCartItems);

    const cartTotal = useSelector(selectCartTotal);

    const currentUser = useSelector(selectCurrentUser);

    const navigate = useNavigate();

    const checkoutBillingHandler = ()=>{
        setShowSpinner(true);
        navigate('/checkout-billing-address');

    }

    return (
        <Fragment>
            <Helmet>
                <title>Checkout Page | Ahguide Shopping Cart </title>
                <meta name="description" content="Ahguide checkout page lists all add to cart products in this single page. You can update quantity,remove and proceed to checkout." />        
                <link rel="canonical" href="https://www.ahguide.in/checkout" />
            </Helmet>
        <div>
            {
             cartItems.length===0 
                
                ? <div style={{margin:20,display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',gap:20}}>
                
                <h1>Your Shopping Cart is empty</h1>
                {
                    !currentUser ? <div>
                    <Button href='/login' variant="warning">Sign in to your account</Button>{'  '}
                    <Button href='/signup' variant="light">Sign up now</Button>
                    </div>
                     : <h3>Add Some Items!</h3>
                }
                
                
                </div>
                              
                : 
        
        <>  
            <h1 style={{textAlign:'center',margin:20}}>Checkout</h1>
            <div style={{display:'flex',justifyContent:'space-evenly',columnGap:'10px'}}>
                
                <div>Image</div>
                <div>Qty</div>
                <div>Name</div>
                <div>Price</div>
                <div>Remove</div>
            </div> 
            {cartItems.map((item)=>{
                return <CheckoutItem product={item} key={item.id}  />
        })}
            <div style={{display:'flex',alignItems:'center',flexDirection:'column',gap:'20px',margin:60}}>
            <div style={{border:'1px solid black',padding:'10px',borderRadius:'10px',fontSize:'20px'}}>Total: Rs. {cartTotal} </div>
            
            {
                showSpinner ? <GrowSpinner />
                  : <Button onClick={checkoutBillingHandler} variant="warning" size='lg'>Proceed to Checkout</Button>
            }
            
           
            </div>
            
            </>         
            }
            
       
            
            
            
        
        
        </div>
        {/* <div style={{position: 'absolute',bottom:0}}><Footer /></div> */}
       
        </Fragment>
    ) 

}


export default Checkout;